&.maier-media-display-block {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 4rem 0;
    row-gap: 4rem;

    .maier-media-display-block-list {
        column-gap: 2rem;
        display: flex;
        justify-content: center;
        width: 100%;

        @include respond-to('extra-large') {
            flex-direction: column;
            position: relative;
        }

        .maier-media-display {
            flex-flow: wrap;
            height: 50rem;
            padding: 0;

            .maier-media-display-list {
                flex-direction: column;
                height: 100%;
                padding: 0;
                row-gap: 2rem;
                width: 100%;


                @include respond-to('extra-large') {
                    flex-direction: row;
                    row-gap: 0;
                }

                > a {
                    flex: 1;
                    width: 100%;
                }

                .maier-media-display-list-item {
                    flex: 1;
                    height: 100%;

                    .maier-media-display-list-item-media {
                        flex: 1;
                        height: auto;

                        .maier-media-display-list-item-media-content {
                            .maier-media-display-list-item-media-content-surtitle {
                                font-size: $maier-font-larger;
                                margin-bottom: 1rem;
                            }
                        }
                    }
                }
            }
        }

        // FROM: slickjs
        .slick-arrow {
            position: absolute;
            right: 2rem;
        }

        .slick-track {
            margin: 0;

            .slick-slide {
                padding-left: 0;
            }
        }
    }
}