body > header {
    font-family: $maier-primary-font;
    font-weight: $maier-font-weight-light;

    &.sticky {
        @include respond-to("extra-large-up") {
            background-color: $maier-color-white;
            height: 8rem;
            position: sticky;
            top: 0;
            width: 100%;
            z-index: 1000;
        }

        + main {
            @include respond-to("extra-large-up") {
                padding-top: 8rem;
            }
        }

        .my-account, .shopping-cart, .gift-lists, .help, .locale, .maier-breadcrumb-container {
            @include respond-to("extra-large-up") {
                display: none;
            }
        }

        .maier-pre-header {
            @include respond-to("extra-large-up") {
                display: none;
            }
        }

        .maier-header {
            @include respond-to("extra-large-up") {
                background-color: $maier-background-color-primary;
                display: block;
                height: 100%;
                padding-top: 0;
                position: absolute;
                width: 100%;
            }

            .user-management {
                height: 100%;
            }

            .navigation {
                @include respond-to("extra-large-up") {
                    height: 100%;
                    padding-top: 0 !important;
                    margin-top: -8rem;
                }

                .menu {
                    ul {
                        li {
                            a {
                                @include respond-to("extra-large-up") {
                                    color: $maier-color-black !important;
                                }
                            }
                        }
                    }

                    > ul {
                        @include respond-to("extra-large-up") {
                            height: 8rem;
                        }

                        > li {
                            @include respond-to("extra-large-up") {
                                align-items: center;
                                height: 8rem;
                            }

                            &:first-child {
                                @include respond-to("extra-large-up") {
                                    display: none;
                                }
                            }
                        }
                    }

                    .display {
                        @include respond-to("extra-large-up") {
                            margin-top: 8rem;
                        }
                    }
                }

                .search {
                    @include respond-to("extra-large-up") {
                        align-self: center;
                        display: flex;
                        justify-content: right;
                        margin-right: 8rem;
                    }

                    .search-form {

                        #product_search_form_autocomplete {
                            &.open {
                                display: none;
                            }
                        }

                        div {
                            position: relative;
                        }

                        .maier-icon {
                            display: block;
                            font-size: $maier-font-x-large;
                            left: -1rem;
                            position: absolute;
                            top: 0;
                        }

                        .search-input {
                            background-color: $maier-color-white;
                            padding-left: 3rem;
                            width: 65%;
                        }
                    }
                }
            }
        }
    }

    .maier-pre-header {
        align-items: center;
        background-color: $maier-color-dark-green;
        display: flex;
        height: 4rem;
        overflow: hidden;

        @include respond-to("extra-large") {
            height: auto;
        }

        .maier-pre-header-content {
            color: $maier-color-white;
            height: 2.6rem;
            margin: auto;
            text-align: center;
            text-transform: uppercase;

            @include respond-to("extra-large") {
                height: auto;
                padding: 1rem;
            }

            > * {
                vertical-align: middle;
            }

            i {
                font-size: $maier-font-larger;
                margin-right: 1rem;
            }

            .maier-pre-header-content-description {
                font-weight: $maier-font-weight-large;

                @include respond-to("medium") {
                    font-size: 1.4rem;
                }
            }

            .maier-pre-header-content-separator {
                font-weight: $maier-font-weight-large;
                margin-left: 1rem;
            }

            .maier-pre-header-content-link {
                color: $maier-color-white;
                font-weight: $maier-font-weight-large;
                margin-left: 2rem;
                transition: all 0.5s ease-in-out;

                &:hover {
                    color: $maier-color-white;
                    margin-left: 2.5rem;
                }

                @include respond-to("medium") {
                    font-size: $maier-font-xxx-small;
                    margin-left: 1rem;
                    font-size: 1.4rem;
                }
            }
        }
    }

    .maier-header {
        border-bottom: 1px solid $maier-color-grey;
        display: flex;
        flex-direction: column;
        padding: 0 $maier-layout-default-padding;
        padding-top: 2rem;
        position: relative;

        @include respond-to("extra-large") {
            margin-top: 0;
            padding: 0;
        }

        .show-mobile-header-menu-button {
            display: none;

            &:checked {
                & ~ .navigation {
                    display: flex;
                }

                & ~ .search-mobile {
                    display: none;
                }
            }
        }

        .show-mobile-header-menu-label {
            display: none;
            margin-left: 2rem;

            i {
                font-size: 2rem;
            }

            @include respond-to("extra-large") {
                display: block;
            }
        }

        .locale {
            font-weight: $maier-font-weight-thin;
            left: 2.5rem;
            margin: 0;
            text-align: center;
            width: 3rem;

            .display-locales {
                border: 1px solid black;
                border-top: 0;
                display: none;
                list-style: none;
                pointer-events: none;
                margin-top: -2rem;

                li {
                    pointer-events: all;
                }
            }
        }

        .user-management {
            display: flex;
            font-size: $maier-font-xxx-small;
            justify-content: space-between;

            @include respond-to("extra-large") {
                padding: 1.5rem 0.5rem;
            }

            .actions {
                align-items: center;
                column-gap: 4rem;
                display: flex;
                width: 40%;

                &.actions-secondary {
                    column-gap: 5.5rem;
                    justify-content: right;
                }

                @include respond-to("extra-large") {
                    width: 15%;
                }

                a, span {
                    font-weight: $maier-font-weight-light;
                    text-decoration: none;
                }

                button {
                    background: none;
                    font-weight: $maier-font-weight-light;
                    text-transform: uppercase;
                    padding: 0;
                }

                .locale-desktop {
                    position: absolute;

                    @include respond-to("extra-large") {
                        display: none;
                    }

                    .display-locales {
                        padding: 2rem 1rem 0 0.5rem;
                        position: absolute;
                    }

                    label[for="show-locales-desktop"] {
                        display: flex;
                        background-color: $maier-background-color-primary;
                        padding-left: 0.5rem;

                        img {
                            width: 1rem;
                        }
                    }

                    .show-locales {
                        display: none;

                        &:checked {
                            & + label[for="show-locales-desktop"] {
                                img {
                                    transform: rotate(180deg);
                                }
                            }
                            ~.display-locales {
                                display: flex;
                            }
                        }
                    }
                }

                .help {
                    @include respond-to("extra-large") {
                        display: none;
                    }

                    label[for="show-help"] {
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        row-gap: 0.9rem;

                        i {
                            font-size: $maier-font-larger;
                        }
                    }

                    .display-help {
                        background-color: $maier-background-color-secondary;
                        display: none;
                        padding: 2rem;
                        position: absolute;
                        z-index: 2000;

                        p {
                            color: $maier-text-color-secondary;
                            font-size: $maier-font-smaller;
                            text-align: center;
                            text-transform: uppercase;
                        }

                        ul {
                            text-align: left;

                            li {
                                font-size: $maier-font-xx-small;
                                font-weight: $maier-font-weight-thin;
                                margin-top: 1.5rem;
                                text-transform: none;

                                i {
                                    font-size: $maier-font-large;
                                    margin-right: 1rem;
                                    vertical-align: middle;
                                }
                            }
                        }
                    }

                    .show-help {
                        display: none;

                        &:checked {
                            ~.display-help {
                                align-items: center;
                                display: flex;
                                flex-direction: column;
                            }
                        }
                    }

                }

                .shopping-cart {
                    position: relative;

                    .cart {
                        @include respond-to("extra-large") {
                            margin-right: 2rem;
                        }

                        .cart-label {
                            @include respond-to("extra-large") {
                                display: none;
                            }
                        }

                        .cart-product-count {
                            background-color: $maier-text-color-secondary;
                            border-radius: 50%;
                            color: $maier-text-color-tertiary;
                            font-size: 0.9rem;
                            font-weight: bold;
                            line-height: 0.5rem;
                            padding: 0.5rem;
                            position: absolute;
                            right: -0.5rem;
                            top: -0.5rem;

                            @include respond-to("extra-large") {
                                right: 1.25rem
                            }
                        }
                    }

                    form {
                        .cart {
                            align-items: center;
                            display: flex;
                            flex-direction: column;
                            row-gap: 1.2rem;

                            @include respond-to("extra-large") {
                                height: 100%;
                            }

                            &::before {
                                content: '\e90e';
                                font-family: 'Icomoon';
                                font-size: 2.6rem;
                            }

                            span {
                                @include respond-to("extra-large") {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                .gift-lists {
                    @include respond-to("extra-large") {
                        display: none;
                    }
                }

                .my-account {
                    @include respond-to("extra-large") {
                        display: none;
                    }

                    .logout {
                        color: $maier-text-color-secondary;
                        font-size: $maier-font-xxxx-small;
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                .rolex-retailer-clock-container {
                    @include respond-to("extra-large") {
                        display: none;
                    }
                }

                > div {
                    text-transform: uppercase;
                    text-align: center;

                    @include respond-to("extra-large") {
                        margin: 0;
                    }

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    > a {
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        row-gap: 0.9rem;
                        padding: 0;

                        i {
                            font-size: $maier-font-larger;
                        }
                    }
                }
            }

            .maier-logo {
                background-image: url("/bundles/maierfrontcommon/images/common/maier-logo.png");
                background-position: center;
                background-repeat: no-repeat;
                background-size: 60%;
                max-width: 30rem;
                text-indent: -9999px;
                width: 20%;
                z-index: 1000;

                @include respond-to("extra-large") {
                    background-size: 30%;
                    height: 4.8rem;
                    width: 50%;
                }

                @include respond-to("medium") {
                    background-size: 60%;
                    width: 40%;
                }

                @include respond-to("tiny") {
                    background-size: 80%;
                }
            }
        }

        .search-mobile {

            @include respond-to("extra-large-up") {
                display: none;
            }

            @include respond-to("extra-large") {
                padding: 1rem;
                padding-top: 0;
            }

            form {
                position: relative;

                #product_search_form_mobile_autocomplete {
                    display: none;

                    &.open {
                        background-color: white;
                        border-radius: 10px;
                        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
                        display: block;
                        padding-top: 3rem;
                        position: absolute;
                        top: calc(100% - 3rem);
                        width: 100%;
                        z-index: 1000;
                    }

                    & + .maier-input {
                        z-index: 1001;
                    }

                    .product-search-form-results {
                        & > span {
                            display: none;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;

                            li {

                                &:not(:last-child) {
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                }

                                a {
                                    column-gap: 4rem;
                                    display: flex;
                                    padding: 2rem 4rem;

                                    img {
                                        height: 10rem;
                                        flex-basis: 20%;
                                        object-fit: cover;
                                    }

                                    > span {
                                        display: flex;
                                        flex-basis: 80%;
                                        flex-direction: column;
                                        justify-content: space-between;

                                        .product-name {
                                            order: 1;
                                        }

                                        .product-brand {
                                            font-weight: bold;
                                            order: 0;
                                        }

                                        .product-price {
                                            order: 2;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                #search-submit, .search-submit {
                    @include respond-to("extra-large") {
                        display: none;
                    }
                }

                .search-input {
                    @include respond-to("extra-large") {
                        background-color: $maier-background-color-secondary;
                        border: 0;
                        border-radius: 30px;
                        box-shadow: none;
                        margin: 0;
                        padding: 0.5rem 1rem;
                        padding-left: 5rem;
                        width: 100%;
                    }

                    &:focus-visible {
                        @include respond-to("extra-large") {
                            outline: none;
                        }
                    }
                }

                div:nth-child(2) {
                    @include respond-to("extra-large") {
                        position: relative;
                    }

                    .maier-icon {
                        @include respond-to("extra-large") {
                            font-size: $maier-font-large;
                            left: 1rem;
                            position: absolute;
                            top: 0.5rem;
                        }
                    }
                }
            }

            label {
                display: none;
            }

            button {
                background-color: transparent;
                font-size: 0;
                padding: 1rem 0.5rem;
                position: absolute;
                top: 0;

                &::before {
                    content: '';
                }
            }
        }

        .navigation {
            display: flex;
            justify-content: space-between;
            padding-top: 2rem;

            @include respond-to("extra-large") {
                display: none;
                flex-direction: column;
                padding-top: 0;
            }

            .submenu-mobile {
                display: none;
                flex-direction: column;
                padding-bottom: 2rem;
                text-transform: uppercase;

                @include respond-to("extra-large") {
                    display: flex;
                }

                div {
                    margin-top: 1rem;
                }

                i {
                    margin-right: 2rem;
                }

                .locale-mobile {
                    position: inherit;
                    font-size: small;

                    .display-locales {
                        padding: 2rem 3rem 0 0.5rem;
                        position: relative;
                    }

                    label[for="show-locales-mobile"] {
                        display: flex;
                        font-weight: 500;
                        padding-left: 0.5rem;

                        img {
                            min-width: 1rem;
                        }
                    }

                    .show-locales {
                        display: none;

                        &:checked {
                            & + label[for="show-locales-mobile"] {
                                img {
                                    transform: rotate(180deg);
                                }
                            }
                            ~.display-locales {
                                display: flex;
                            }
                        }
                    }
                }

                .rolex-retailer-clock-container {
                    border-top: 1px solid $maier-color-light-grey;
                    display: flex;
                    justify-content: center;
                    padding-top: 2rem;

                    .rolex-retailer-clock {
                        margin-top: 0;
                    }
                }

                .my-account {
                    display: flex;
                    flex-direction: column;
                    margin-left: 2rem;

                    .logout {
                        color: $maier-text-color-secondary;
                        font-size: $maier-font-xxxx-small;
                        text-decoration: underline;
                        margin-left: 3.8rem;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                .gift-lists, .contact, .phone, .shops, .locale {
                    margin-left: 2rem;
                }
            }

            .menu {
                align-items: center;
                display: flex;
                flex-basis: 60%;
                font-size: $maier-font-xx-small;

                @include respond-to("extra-large") {
                    padding-top: 2.5rem;
                    width: 100%;
                }

                > ul {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    margin-left: -1rem;
                    text-transform: uppercase;

                    @include respond-to("extra-large") {
                        flex-direction: column;
                        width: 100%;
                        margin-left: 0;
                    }

                    li {
                        display: flex;

                        @include respond-to("extra-large") {
                            border-bottom: 1px solid $maier-color-light-grey;
                            flex-direction: column;
                            width: 100%;
                        }

                        &:first-child {
                            @include respond-to("extra-large") {
                                border-top: 1px solid $maier-color-light-grey;
                            }
                        }

                        &:hover {
                            .display {
                                display: block;

                                @include respond-to("extra-large") {
                                    display: none;
                                }
                            }
                        }

                        .label {
                            display: flex;
                            justify-content: space-between;

                            a {
                                font-weight: $maier-font-weight-medium;
                                padding: 1rem;
                                text-decoration: none;
                                z-index: 1500;

                                &.second-hands {
                                    color: $maier-color-orange;
                                }

                                @include respond-to("extra-large") {
                                    font-size: $maier-font-small;
                                    font-weight: $maier-font-weight-medium;
                                    padding: 1.5rem;
                                }
                            }

                            .mobile-menu-label {
                                display: none;

                                @include respond-to("extra-large") {
                                    display: flex;
                                    font-size: 4rem;
                                    line-height: 2rem;
                                    padding: 1.5rem 2rem;

                                    &::before {
                                        content: '+';
                                    }
                                }
                            }
                        }

                        .mobile-menu-input {
                            display: none;

                            &:checked {
                                & ~ .display {
                                    @include respond-to("extra-large") {
                                        border: none;
                                        display: flex;
                                        position: static;
                                    }
                                }

                                & ~ .label .mobile-menu-label {
                                    &::before {
                                        content: '-';
                                    }
                                }
                            }
                        }

                        .display {
                            align-self: start;
                            background-color: $maier-background-color-secondary;
                            border-bottom: 1px solid $maier-color-grey;
                            display: none;
                            left: 0;
                            margin-top: 4rem;
                            position: absolute;
                            width: 100%;
                            z-index: 3000;
                            @include fade-in('.5s');

                            @include respond-to("extra-large") {
                                background-color: $maier-background-color-primary;
                                margin-top: 0;

                                &:hover {
                                    display: none;
                                }
                            }

                            &:hover {
                                display: block;
                            }

                            .display-container {
                                width: 100%;

                                .content {
                                    display: flex;
                                    font-size: $maier-font-xxx-small;
                                    height: 60rem;
                                    justify-content:  space-between;

                                    @include respond-to("extra-large") {
                                        flex-direction: column;
                                        height: auto;
                                    }

                                    &.menu-maier-spirit {
                                        .links {
                                            justify-content: left;

                                            > .list:nth-child(1) {
                                                flex-basis: 30%;
                                            }

                                            > .list:nth-child(2) {
                                                flex-basis: 50%;

                                                @include respond-to("extra-large") {
                                                    margin-left: 0;
                                                }

                                                ul {
                                                    column-gap: 4rem;
                                                    flex-direction: row;
                                                    justify-content: right;

                                                    @include respond-to("extra-large") {
                                                        flex-direction: column;
                                                    }

                                                    li {
                                                        flex-basis: calc(50% - 2rem);

                                                        @include respond-to("extra-large") {
                                                            flex-basis: 100%;

                                                            &:nth-child(even), &:last-child {
                                                                order: 10;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    &.menu-watches {
                                        .links {
                                            .list-group {
                                                flex-basis: 30%;
                                            }

                                            > .list {
                                                flex-basis: 70%;
                                            }
                                        }
                                    }

                                    &.menu-jewels {
                                        .links {
                                            .list-group {
                                                flex-direction: column;
                                                flex-wrap: wrap;
                                            }

                                            > .list {
                                                flex-basis: 40%;

                                                &:last-child {
                                                    height: 100%;
                                                }
                                            }
                                        }
                                    }

                                    &.menu-second-hand {
                                        .links {
                                            .list-group {
                                                flex-basis: 30%;
                                                row-gap: 2rem;

                                                @include respond-to("extra-large") {
                                                    row-gap: 0;
                                                }
                                            }

                                            > .list {
                                                flex-basis: 70%;
                                            }
                                        }
                                    }

                                    & > div {
                                        display: flex;
                                        width: 50%;

                                        @include respond-to("extra-large") {
                                            width: 100%;
                                        }

                                        ul {
                                            align-items: flex-start;
                                            column-gap: 2rem;
                                            display: flex;
                                            flex-direction: column;
                                            flex-wrap: wrap;
                                            justify-content: flex-start;
                                            text-transform: none;

                                            @include respond-to("extra-large") {
                                                flex-wrap: nowrap;
                                                margin-left: 1rem;
                                            }

                                            li {
                                                border: 0;
                                                padding: 0.5rem 0;

                                                @include respond-to("extra-large") {
                                                    font-weight: $maier-font-weight-medium;
                                                    padding: 0.5rem;
                                                }

                                                a {
                                                    @include respond-to("extra-large-up") {
                                                        font-weight: $maier-font-weight-medium;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    &.all-column-display {
                                        .links {
                                            .list-group {
                                                @include respond-to("extra-large") {
                                                    flex-direction: column;
                                                }
                                            }

                                            > .list {
                                                flex-basis: 40%;
                                            }
                                        }
                                    }

                                    &.medias-link-display {
                                        height: auto;

                                        .links {
                                            justify-content: center;
                                            width: 100%;

                                            .list {
                                                ul {
                                                    display: flex;
                                                    flex-direction: row;
                                                    flex-wrap: nowrap;

                                                    li {
                                                        a {
                                                            align-items: center;
                                                            display: flex;
                                                            flex-direction: column;

                                                            span {
                                                                margin-top: 1rem;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    &.only-one-column-display {
                                        .links {
                                            justify-content: flex-end;

                                            > .list {
                                                flex-basis: 70%;
                                            }
                                        }
                                    }

                                    .links {
                                        justify-content: space-between;
                                        padding: 4rem;
                                        padding-bottom: 2rem;
                                        padding-left: $maier-layout-default-padding;

                                        @include respond-to("extra-large") {
                                            flex-direction: column;
                                            padding: 0rem;
                                            width: 100%;
                                        }

                                        > .list {
                                            flex-basis: 50%;
                                        }

                                        .list-group {
                                            flex-basis: 50%;

                                            @include respond-to("extra-large-up") {
                                                row-gap: 4rem;
                                            }

                                            @include respond-to("extra-large") {
                                                flex-basis: auto;
                                            }
                                        }

                                        .list {
                                            @include respond-to("extra-large") {
                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: wrap;
                                                justify-content: space-between;
                                                width: 100%;
                                            }

                                            input {
                                                display: none;

                                                &:checked {
                                                    ~ ul {
                                                        display: flex;
                                                    }

                                                    & ~ .display-label {
                                                        &:before {
                                                            content: '-';
                                                        }
                                                    }
                                                }
                                            }

                                            .list-title {
                                                color: $maier-text-color-secondary;
                                                font-weight: $maier-font-weight-large;
                                                min-height: 2rem;

                                                @include respond-to("extra-large") {
                                                    padding: 1.5rem;
                                                }
                                            }

                                            .display-label {
                                                color: $maier-color-orange;
                                                display: none;

                                                @include respond-to("extra-large") {
                                                    display: block;
                                                    font-size: 3rem;
                                                    line-height: 1.7rem;
                                                    padding: 1.5rem 2rem;

                                                    &:before {
                                                        content: '+';
                                                    }
                                                }
                                            }

                                            label {
                                                @include respond-to("extra-large") {
                                                    display: contents;
                                                }

                                                a {
                                                    @include respond-to("extra-large") {
                                                        padding: 1.5rem;
                                                    }

                                                    .list-title {
                                                        @include respond-to("extra-large") {
                                                            padding: 0;
                                                        }
                                                    }
                                                }
                                            }

                                            span {
                                                @include respond-to("extra-large") {
                                                    padding: 1.5rem;
                                                }
                                            }

                                            ul {
                                                margin-bottom: 1rem;

                                                @include respond-to("extra-large") {
                                                    display: none;
                                                    flex-basis: 100%;
                                                    flex-direction: column;
                                                    flex-wrap: nowrap;
                                                }

                                                li {
                                                    border: 0;
                                                    text-transform: uppercase;
                                                }
                                            }
                                        }

                                        div {
                                            display: flex;
                                            flex-direction: column;
                                        }
                                    }

                                    .widgets {
                                        display: flex;
                                        flex-direction: column;
                                        margin: 0%;

                                        @include respond-to("extra-large") {
                                            display: none;
                                            row-gap: 1rem;
                                        }

                                        > * {
                                            height: 100%;
                                        }

                                        a {
                                            height: 100%;

                                            &:only-child {
                                                height: 50%;
                                            }

                                            img {
                                                height: 100%;

                                                @include respond-to("extra-large") {
                                                    width: 100%;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .search {
                flex-basis: 35%;
                position: relative;
                z-index: 1001;

                @include respond-to("extra-large") {
                    display: none;
                }

                .search-form {
                    position: relative;

                    #product_search_form_autocomplete {
                        display: none;

                        &.open {
                            background-color: white;
                            border-radius: 10px;
                            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
                            display: block;
                            padding-top: 3rem;
                            position: absolute;
                            top: calc(100% - 3rem);
                            width: 100%;
                            z-index: 1000;
                        }

                        .product-search-form-results {
                            & > span {
                                display: none;
                            }

                            ul {
                                display: flex;
                                flex-direction: column;

                                li {

                                    &:not(:last-child) {
                                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                    }

                                    a {
                                        column-gap: 4rem;
                                        display: flex;
                                        padding: 2rem 4rem;

                                        img {
                                            height: 10rem;
                                            flex-basis: 20%;
                                            object-fit: cover;
                                        }

                                        > span {
                                            display: flex;
                                            flex-basis: 80%;
                                            flex-direction: column;
                                            justify-content: space-between;

                                            .product-name {
                                                order: 1;
                                            }

                                            .product-brand {
                                                font-weight: bold;
                                                order: 0;
                                            }

                                            .product-price {
                                                order: 2;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    #search-submit, .search-submit {
                        display: none;
                    }

                    .search-input {
                        background-color: $maier-background-color-secondary;
                        border: 0;
                        border-radius: 30px;
                        box-shadow: none;
                        margin: 0;
                        padding: 0.5rem 1rem;
                        padding-left: 5rem;
                        position: relative;
                        width: 100%;
                        z-index: 1001;


                        &:focus-visible {
                            outline: none;
                        }
                    }

                    .maier-icon {
                        font-size: $maier-font-large;
                        left: 1rem;
                        position: absolute;
                        top: 0.5rem;
                        z-index: 1002;
                    }
                }

                label {
                    display: none;
                }

                button {
                    background-color: transparent;
                    font-size: 0;
                    padding: 1rem 0.5rem;
                    position: absolute;
                    top: 0;

                    &::before {
                        content: '';
                    }
                }
            }
        }
    }

    .maier-breadcrumb-container {
        padding: 1rem $maier-layout-default-padding;

        @include respond-to("extra-large") {
            padding: 1rem;
        }

        .maier-breadcrumb {
            color: $maier-text-color-secondary;
            display: flex;
            flex-wrap: wrap;
            margin-left: -0.5rem;
            list-style: none;
            text-transform: uppercase;

            @include respond-to("extra-large") {
                padding: 1rem 1.5rem;
            }

            li {
                align-self: center;
                font-size: $maier-font-xx-small;

                &:not(:last-child) {
                    margin-right: 0.5rem;

                    &::after {
                        content: "/";
                    }
                }

                a {
                    color: $maier-text-color-secondary;
                    display: inline-block;
                    font-weight: $maier-font-weight-light;
                    padding: 0.5rem;
                    text-decoration: none;
                }
            }
        }
    }
}