.maier-modal {
    @import 'modal/all';

    .maier-customer-container {
        padding: 0;

        .maier-customer-section {
            width: 100%;
        }
    }
}