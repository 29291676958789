.maier-product {
    border: 1px solid $maier-color-grey;
    display: flex;
    flex-direction: column;
    font-size: $maier-font-x-small;
    padding: 1.5rem;
    row-gap: .5rem;
    text-align: center;
    width: 45rem;

    .maier-product-availability {
        @extend %maier-button-availability-inverse;
    }

    .maier-product-brand {
        font-weight: $maier-font-weight-medium;
        text-transform: uppercase;
    }

    .maier-product-label, .maier-product-description {
        align-items: center;
        display: flex;
        height: 4.8rem;
        justify-content: center;
    }

    .maier-product-label {
        text-transform: uppercase;
    }

    .maier-product-description {
        font-style: italic;
    }

    .maier-product-variation-link {
        color: $maier-text-color-secondary;
        font-size: $maier-font-xx-small;
    }

    .maier-product-price {
        @include respond-to("extra-large-up") {
            margin-top: auto;
        }

        .maier-product-price-amount {
            font-weight: $maier-font-weight-medium;
        }

        .maier-product-price-old {
            color: $maier-text-color-quinary;
            margin-right: 0.5rem;
            text-decoration: line-through;

            & + .maier-product-price-amount {
                color: $maier-text-color-secondary;
            }
        }
    }

    .maier-product-media {
        display: block;
        height: 40rem;
        width: 40rem;
        position: relative;

        @include respond-to("extra-large") {
            height: 35rem;
            margin: auto;
        }

        & + .maier-product-brand {
            @include respond-to("extra-large-up") {
                margin-top: 4.6rem;
            }
        }

        &.has-alternate {
            &:hover {
                .maier-product-media-main {
                    @include respond-to("extra-large-up") {
                        opacity: 0;
                    }
                }

                .maier-product-media-alternate {
                    @include respond-to("extra-large-up") {
                        opacity: 1;
                    }
                }
            }

            .maier-product-media-main, .maier-product-media-alternate {
                position: absolute;
                transition: opacity .3s ease-in;
            }

            .maier-product-media-alternate {
                opacity: 0;

            }

            .maier-product-media-main {
                opacity: 1;
            }
        }

        .maier-product-media-pellet-list {
            display: flex;
            flex-direction: column;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 100;

            .maier-product-media-pellet-list-item {
                height: 6rem;
                width: 6rem;

                &.maier-product-media-pellet-list-item-second-hand {
                    background-image: url(/bundles/maierfrontcommon/images/website/maier_main/widget/maier_product/occasion-label.png);
                    background-position: 50%;
                    background-repeat: no-repeat;
                }

                &.maier-product-media-pellet-list-item-exclusivity {
                    width: 10rem;
                }

                &.maier-product-media-pellet-list-item-discount {
                    background-color: $maier-color-orange;
                    border-radius: 50%;
                    color: $maier-text-color-tertiary;
                    font-weight: $maier-font-weight-large;
                    line-height: 6rem;
                    text-align: center;
                    transform: scale(0.8);

                    sup {
                        font-size: 100%;
                        font-weight: $maier-font-weight-large;
                        top: -0.25rem;
                    }
                }
            }
        }

        .maier-product-media-main, .maier-product-media-alternate {
            img {
                object-fit: contain;
            }
        }
    }
}