&.maier-section-brands {
    margin: 4rem 0;
    row-gap: 4rem;

    &.maier-section-brands-hover-effect {
        .maier-section-brands-list {
            .maier-section-brands-list-item {
                &:hover {
                    > a {
                        @include respond-to("extra-large-up") {
                            flex-direction: column;
                        }
                    }

                    .maier-section-brands-list-item-cover {
                        @include respond-to("extra-large-up") {
                            display: none;
                        }
                    }

                    .maier-section-brands-list-item-logo {
                        @include respond-to("extra-large-up") {
                            @include fade-in('.5s');
                        }
                    }
                }
            }
        }
    }

    .maier-section-brands-list {
        display: flex;
        column-gap: 2.5rem;
        flex-wrap: wrap;
        padding: 0 10rem;
        padding-left: 15rem;
        row-gap: 1.5rem;

        @include respond-to('extra-large') {
            column-gap: 0.5rem;
            padding: 0 0.5rem;
            padding-left: 1rem;
            row-gap: 0.5rem;
        }

        .maier-section-brands-list-item {
            background-color: $maier-background-color-secondary;
            flex: 1 0 calc(20% - 2.5rem);
            max-width: calc(20% - 2.5rem);
            height: 14rem;

            @include respond-to('extra-large') {
                flex-basis: calc(50% - 0.5rem);
                height: 15rem;
                max-width: calc(50% - 0.5rem);
            }

            a {
                align-items: center;
                column-gap: 1rem;
                display: flex;
                height: 100%;
                padding: 0 2rem;

                .maier-section-brands-list-item-cover, .maier-section-brands-list-item-logo {
                    img {
                        object-fit: contain;
                    }
                }
            }
        }
    }
}