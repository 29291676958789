.maier-customer-page-cart-show {
    .maier-customer-cart {
        text-align: center;
        display: flex;
        flex-direction: column;
        row-gap: 4rem;
        width: 100%;

        @include respond-to('extra-large') {
            padding: 1rem 0;
            row-gap: 2rem;
        }

        &.maier-customer-cart-empty {
            .maier-icon-shopping-cart {
                font-size: 8rem;
            }

            .maier-customer-cart-description {
                font-size: $maier-font-larger;
                font-weight: $maier-font-weight-medium;
                margin-bottom: 0;
                text-transform: uppercase;
            }

            .maier-link {
                text-transform: uppercase;
            }
        }
    }
}