form.maier-form {
    @import 'custom/all';

    .maier-form-title {
        font-size: $maier-font-larger;
        font-weight: $maier-font-weight-medium;
        margin-bottom: 2rem;
        text-align: center;
        text-transform: uppercase;
        width: 100%;

        @include respond-to('extra-large') {
            font-size: $maier-font-small;
            font-weight: $maier-font-weight-large;
            text-align: center;
        }
    }

    .maier-form-description {
        text-align: center;
        margin: 4rem 0;
    }

    .maier-form-row {
        column-gap: 8rem;
        display: flex;
        flex-direction: row;

        @include respond-to('extra-large') {
            flex-direction: column;
        }

        > * {
            display: flex;
            flex-basis: 100%;
            flex-direction: column;
            column-gap: 2rem;
        }
    }
}