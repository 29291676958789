&.maier-customer-gift-workflow-payment-methods {
    .maier-customer-workflow-payment-method {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;

        .maier-customer-workflow-payment-method-apple-pay {
            display: none;
        }

        > div {
            input {
                display: none;

                &:checked ~ label {
                    background-color: $maier-color-light-grey;
                    border: 1px solid $maier-color-orange;
                }
            }

            label {
                align-items: center;
                background-color: $maier-background-color-secondary;
                display: flex;
                flex-direction: column;
                margin: 0;
                padding: 4rem 2rem;
                row-gap: 2rem;

                &:hover {
                    transition: 0.3s ease-in-out all;;
                    background-color: $maier-text-color-secondary;
                    color: $maier-text-color-tertiary
                }

                strong {
                    text-transform: uppercase;
                }
            }
        }
    }
}