$maier-section-tertiary-border-size: 23px; // 23px as requested by MAIER

&.maier-section-horizontal {
    &.maier-section-horizontal-media {
        align-items: stretch;
        display: flex;
        flex-direction: row;
        padding: 4rem 0;
        width: 100%;

        @include respond-to('extra-large') {
            flex-direction: column;
            height: 100%;
            padding: 0;
            row-gap: 0;
        }

        &.bordered:not(:last-child) {
            @include respond-to('extra-large') {
                padding-bottom: 4rem;
            }
        }

        &.maier-section-horizontal-media-inverse-background {
            background-color: $maier-background-color-secondary;
            padding: 0;

            .maier-section-horizontal-media-content {
                @include respond-to('extra-large-up') {
                    padding-left: 4rem;
                    padding-right: $maier-layout-default-padding;
                }

                .maier-section-horizontal-media-content-title {
                    border-left: 1px solid $maier-color-orange;
                    color: $maier-text-color-secondary;
                    padding-left: 1rem;
                    text-transform: none;
                }

                .maier-section-horizontal-media-content-link {
                    &:hover {
                        text-decoration-color: $maier-color-orange;
                    }
                }
            }
        }

        &.maier-section-horizontal-media-overflow {
            .maier-section-horizontal-media-content {
                background-color: $maier-background-color-primary;
                left: -$maier-layout-default-padding;
                margin: 4rem 0;
                padding: 4rem;
                position: relative;

                @include respond-to('extra-large') {
                    position: static;
                }
            }
        }

        &.maier-section-horizontal-media-left {
            .maier-section-horizontal-media-medium {
                order: 0;
            }

            .maier-section-horizontal-media-content {
                order: 1;
            }
        }

        &.maier-section-horizontal-media-right {
            &.maier-section-horizontal-media-overflow {
                .maier-section-horizontal-media-content {
                    left: 15rem;
                }
            }

            .maier-section-horizontal-media-medium {
                order: 1;

                @include respond-to('extra-large') {
                    order: 0;
                }
            }

            .maier-section-horizontal-media-content {
                order: 0;

                @include respond-to('extra-large') {
                    order: 1;
                }
            }
        }

        &.maier-section-horizontal-media-right-mobile {
            .maier-section-horizontal-media-medium {
                @include respond-to('extra-large') {
                    order: 1;
                }
            }

            .maier-section-horizontal-media-content {
                @include respond-to('extra-large') {
                    order: 0;
                }
            }
        }

        &.maier-section-horizontal-media-big {
            .maier-section-horizontal-media-medium {
                flex-basis: 60%;
            }

            .maier-section-horizontal-media-content {
                flex-basis: 40%;
            }
        }

        &.maier-section-horizontal-media-small {
            .maier-section-horizontal-media-medium {
                flex-basis: 40%;
            }

            .maier-section-horizontal-media-content {
                flex-basis: 60%;
            }
        }

        .maier-section-horizontal-media-content {
            align-items: flex-start;
            display: flex;
            flex-basis: 50%;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            margin: auto;
            padding-left: $maier-layout-default-padding;
            padding-right: 4rem;
            row-gap: 4rem;

            @include respond-to('extra-large') {
                padding: 4rem;
            }

            .maier-section-horizontal-media-content-title {
                color: $maier-text-color-primary;
                text-align: left;

                @include respond-to('extra-large') {
                    font-size: $maier-font-small;
                    font-weight: $maier-font-weight-large;
                    text-align: center;
                }
            }

            .maier-section-horizontal-media-content-link {
                @include respond-to('extra-large') {
                    margin: auto;
                    text-align: center;
                }
            }

            .maier-section-horizontal-media-content-description {
                font-size: $maier-font-x-small;
                line-height: 2rem;
                text-align: justify;

                @include respond-to('extra-large') {
                    line-height: 2.5rem;
                    text-align: center;
                }

                a {
                    @extend %maier-link-tertiary;
                }
            }

            .maier-section-horizontal-media-content-address {
                align-self: center;
                font-style: normal;
                margin-bottom: 2rem;
                margin-top: 4rem;
                text-align: center;

                .maier-section-horizontal-media-content-address-label, .maier-section-horizontal-media-content-address-street, .maier-section-horizontal-media-content-address-city, .maier-section-horizontal-media-content-address-phone-number {
                    font-weight: $maier-font-weight-large;
                    margin-bottom: 0;
                }
            }
        }

        .maier-section-horizontal-media-medium {
            flex-basis: 50%;
            position: relative;

            picture {
                img {
                    @include respond-to('extra-large') {
                        max-height: 35rem;
                    }
                }
            }

            .maier-section-horizontal-media-medium-content {
                &.maier-section-horizontal-media-medium-content-tertiary {
                    border: $maier-section-tertiary-border-size solid black;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }

                .maier-section-horizontal-media-medium-content-title {
                    bottom: -0.8rem;
                    color: $maier-text-color-tertiary;
                    font-size: 4rem;
                    font-weight: $maier-font-weight-light;
                    left: 0;
                    line-height: 4.6rem;
                    margin: 0;
                    margin-bottom: 0;
                    padding: 0 2rem;
                    position: absolute;
                    text-align: left;
                    text-transform: uppercase;

                    &.maier-section-horizontal-media-medium-content-title-secondary {
                        color: $maier-text-color-primary;
                    }

                    &.maier-section-horizontal-media-medium-content-title-tertiary {
                        color: $maier-text-color-primary;
                    }
                }
            }
        }
    }

    &.maier-section-horizontal-list {
        column-gap: 4rem;
        display: flex;
        flex-direction: row;
        padding: 4rem 0;
        row-gap: 4rem;

        @include respond-to('extra-large') {
            flex-direction: column;
        }

        &.maier-section-horizontal-list-vertical {
            flex-direction: column;
            padding: 4rem $maier-layout-default-padding;

            @include respond-to('extra-large') {
                padding: 4rem 0 ;
            }

            .maier-section-horizontal-media {
                .maier-section-horizontal-media-content {
                    align-items: start;
                    padding-bottom: 2rem;
                    padding-top: 2rem;

                    .maier-section-horizontal-media-content-title {
                        color: $maier-text-color-primary;
                        text-align: left;

                        @include respond-to('extra-large') {
                            text-align: center;
                        }
                    }

                    .maier-section-horizontal-media-content-description {
                        width: 100%;
                    }
                }
            }
        }

        .maier-section-horizontal-media {
            &.maier-section-horizontal-media-inverse-background {
                .maier-section-horizontal-media-content {
                    .maier-section-horizontal-media-content-title {
                        border-left: none;
                        padding: 0;
                    }
                }
            }

            .maier-section-horizontal-media-content {
                padding: 0 4rem;
                align-items: center;

                .maier-section-horizontal-media-content-title {
                    text-align: center;
                }

                @include respond-to('extra-large') {
                    padding: 3rem 2rem;
                }
            }
        }
    }
}