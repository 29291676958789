/*!
* www.KNACSS.com v7.1.3 (february, 12 2020) @author: Alsacreations, Raphael Goetter
* Licence WTFPL http://www.wtfpl.net/
*/

/* ----------------------------- */
/* ==Table Of Content            */
/* ----------------------------- */

/*
1- Reboot (basic reset)
2- Libraries  :
  - Base
  - Print
  - Layout (alignment, modules, positionning)
  - Utilities (width and spacers helpers)
  - Responsive helpers
  - WordPress reset (disabled by default)
  - Grillade (Grid System)
3- Components :
  - Media object
  - Skip Links for accessibility
  - Tables
  - Forms
  - Buttons
  - Checkbox
  - Tabs
  - Tags
  - Badges
  - Alerts
*/

@import "_vendor/reboot"; // Bootstrap reboot (basic reset) (CSS file renamed and imported as if it was a partial because of libsass)

// WARNING : you should comment the following @import (variables)
// and move variables file from knacss folder to your own project folder!
@import "_config/variables";

@import "_config/mixins";

// Core Libraries
@import "_library/base";         // basic styles
@import "_library/print";        // print quick reset
@import "_library/layout";       // alignment, modules, positionning
@import "_library/utilities";    // width and spacer helpers
@import "_library/responsive";   // Responsive Web Design helpers
// @import "_library/wordpress"; // WordPress reset and basic styles

// New Grid System by default (Grid Layout). If you prefer old "Flexbox" Grid System, replace file with "_library/grillade-flex"
// Note that none of these files are prefixed by an underscore, in order to compile them.
@import "_library/grillade-grid"; // grid system with Grid Layout

// Components
@import "components/media";      // media object
@import "components/skip-links"; // skip links
@import "components/tables";     // data tables consistency
@import "components/forms";      // forms consistency and styles
@import "components/buttons";    // buttons styles
@import "components/checkbox";   // checkbox, radio, switch styles
@import "components/tabs";       // tabs styles
@import "components/arrows";     // arrows styles
@import "components/tags";        // tags styles
@import "components/badges";     // badges styles
@import "components/alerts";     // alerts styles
