.maier-alert {
    border-radius: 0;
    padding: 1rem 2rem;

    &.maier-alert-flash {
        margin: 0 $maier-layout-default-padding;
    }

    &.maier-alert-error {
        border-color: $maier-color-red;
        color: $maier-color-white;
        background: $maier-color-red;
    }

    &.maier-alert-success {
        border-color: $maier-color-green;
        color: $maier-color-white;
        background: $maier-color-green;
    }

    // &.maier-alert-warning {
    //     border-color: $maier-alert-warning-text-color;
    //     color: $maier-alert-warning-text-color;
    //     background: $maier-alert-warning-backgound-color;
    // }

    // &.maier-alert-info {
    //     border-color: $maier-alert-info-text-color;
    //     color: $maier-alert-info-text-color;
    //     background: $maier-alert-info-backgound-color;
    // }
}