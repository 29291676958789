&#maier-modal-footer {
    transition: all ease-in-out .5s;

    @include respond-to('medium') {
        left: 100%;
    }

    &.modal-open {
        @include respond-to('medium') {
            left: 0;
        }

        .modal-content {
            @include respond-to('medium-up') {
                right: 0;
            }
        }
    }

    .modal-content {
        border: none;
        height: 100%;
        left: auto;
        padding: 3rem 4rem;
        top: 0;
        transform: none;
        transition: all ease-in-out .5s;
        width: 33%;

        @include respond-to('medium') {
            padding: 1rem 2rem;
            width: 100%;
        }

        @include respond-to('medium-up') {
            right: -33%;
        }
    }
}