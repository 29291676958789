/**********/
/* Colors */
/**********/

$maier-color-black: #1F1F1F;
$maier-color-red: #F22E2E;
$maier-color-light-black: #2E282A;
$maier-color-grey: #B2B2B2;
$maier-color-light-grey: #E7E7E7;
$maier-color-lighter-grey: #F5F5F5;
$maier-color-white: #FFFFFF;
$maier-color-orange: #CD5B1B;
$maier-color-gold: #EE9D70;
$maier-color-blue: #205198;
$maier-color-dark-green: #43492D;
$maier-color-green: #5CB85C;

$maier-background-color-primary: $maier-color-white;
$maier-background-color-secondary: $maier-color-lighter-grey;
$maier-background-color-tertiary: $maier-color-dark-green;
$maier-background-color-quaternary: $maier-color-black;

$maier-text-color-primary: $maier-color-black;
$maier-text-color-secondary: $maier-color-orange;
$maier-text-color-tertiary: $maier-color-white;
$maier-text-color-quaternary: $maier-color-gold;
$maier-text-color-quinary: $maier-color-dark-green;
$maier-text-color-error: $maier-color-red;

$maier-layout-default-padding: 9rem;
