.maier-section {
    @import 'section/all';

    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: 4rem;

    @include respond-to('extra-large') {
        row-gap: 2rem;
    }

    .maier-section-description {
        font-size: $maier-font-xx-small;
        text-align: left;

        @include respond-to('extra-large') {
            text-align: center;
        }
    }

    .maier-section-media {
        width: 100%;
    }

    .maier-section-title {
        font-size: $maier-font-larger;
        font-weight: $maier-font-weight-medium;
        text-align: center;
        width: 100%;
    }
}