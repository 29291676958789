&.maier-wrapper-shop-product-category-carousel-collections {
    .maier-carousel-list {
        @include respond-to('extra-large') {
            padding: 0 3rem;
        }

        @include respond-to('medium') {
            padding: 0;
        }

        .maier-carousel-list-item {
            @include respond-to('extra-large') {
                padding: 1rem;
            }

            .maier-carousel-list-item-title {
                font-size: $maier-font-medium;

                @include respond-to('extra-large') {
                    display: flex;
                    height: 4.2rem;
                    justify-content: center;
                }

                > b {
                    @include respond-to('extra-large') {
                        display: contents;
                    }
                }
            }
        }
    }
}