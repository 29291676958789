.maier-customer-wishlist-product-list {
    column-gap: 2rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: 2rem;

    .maier-customer-wishlist-product-list-item {
        flex-basis: 25%;
        max-width: calc(25% - 2rem);
        padding: 2rem;

        @include respond-to('extra-large') {
            flex-basis: 50%;
            max-width: calc(50% - 2rem);
        }

        a {
            &:hover {
                .maier-customer-wishlist-product-list-item-description {
                    .maier-customer-wishlist-product-list-item-description-label {
                        color: $maier-text-color-secondary;
                    }
                }
            }

            .maier-customer-wishlist-product-list-item-media {
                position: relative;
                margin-bottom: 1rem;

                .maier-customer-wishlist-product-list-item-media-discount {
                    align-items: center;
                    background-color: $maier-color-orange;
                    border-radius: 100%;
                    color: $maier-text-color-tertiary;
                    display: flex;
                    height: 60px;
                    justify-content: center;
                    left: -2rem;
                    position: absolute;
                    top: -1rem;
                    width: 60px;

                    @include respond-to('extra-large') {
                        height: 45px;
                        width: 45px;
                    }
                }

                form {
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin: 0;

                    button {
                        background-color: transparent;
                        margin: auto;
                        margin-right: 0;
                        padding: 1rem;

                        &:hover {
                            background-color: $maier-color-orange;
                        }
                    }
                }
            }


            .maier-customer-wishlist-product-list-item-description {
                align-items: center;
                display: flex;
                flex-direction: column;
                text-align: center;
                text-transform: uppercase;

                .maier-customer-wishlist-product-list-item-description-brand {
                    font-style: italic;
                }

                .maier-customer-wishlist-product-list-item-description-label {
                    font-weight: $maier-font-weight-medium;
                }

                .maier-customer-wishlist-product-list-item-description-prices {
                    display: flex;
                    flex-direction: column;

                    .maier-customer-wishlist-product-list-item-description-prices-current-price {
                        color: $maier-text-color-secondary;
                        font-weight: $maier-font-weight-medium;
                    }

                    .maier-customer-wishlist-product-list-item-description-prices-old-price {
                        color: $maier-color-dark-green;
                        text-decoration: line-through;
                        font-weight: $maier-font-weight-medium;
                    }
                }
            }
        }
    }
}