.page-configurator-fred {
    .maier-container-configurator-fred {
        h1 {
            text-align: center;
            text-transform: uppercase;
        }

        iframe {
            border: 0 none;
            display: block;
            height: 800px;
            margin-top: 6rem;
        }
    }
}
