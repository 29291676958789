.hide-desktop {
    @include respond-to('extra-large-up') {
        display: none!important
    }
}

.hide-mobile {
    @include respond-to('extra-large') {
        display: none!important;
    }
}