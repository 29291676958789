@keyframes load {
    100% {
        transform: rotate(360deg);
    }
}

.loader-container {
    background: rgba(0,0,0,0.1);
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transition: 1s opacity;
    z-index: $zindex-notification;

    &.visible {
        opacity: 1;
    }

    .loader {
        animation: load 690ms infinite linear;
        border: 3px solid;
        border-color: transparent $maier-color-orange $maier-color-orange;
        border-radius: 50%;
        display: block;
        height: 15rem;
        left: calc(50% - 7.5rem);
        position: absolute;
        top: calc(50% - 7.5rem);
        width: 15rem;
    }
}
