.idci_step_breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    row-gap: 1rem;

    @include respond-to('extra-large') {
        padding: 0 2rem;
    }

    li {
        color: $maier-text-color-secondary;
        font-weight: $maier-font-weight-medium;
        opacity: 0.5;
        position: relative;
        text-transform: uppercase;

        &.active {
            opacity: 1;
        }

        &:not(:last-child) {
            &::after {
                content: "/";
                margin: 0 1rem 0 .5rem;
            }
        }

        a {
            color: $maier-text-color-secondary;
        }
    }
}

form[name="idci_step_navigator"] {
    button[type="submit"] {
        &[id^="idci_step_navigator__path_0"] {
            float: right;
        }

        &#idci_step_navigator__back {
            float: left;
        }
    }
}