.maier-container-filter-form {
    margin: 4rem 0;

    .maier-container-filter-form-view {
        align-items: flex-start;
        column-gap: 4rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0 5rem;

        @include respond-to('extra-large') {
            flex-direction: column;
            padding: 0 1rem;
            row-gap: 2rem;
        }

        .maier-filter-form {
            background-color: $maier-color-lighter-grey;
            display: flex;
            flex-basis: 18%;
            flex-direction: column;
            padding: 4rem 2.5rem;

            @include respond-to('extra-large') {
                padding: 3rem 1.5rem;
                width: 100%;
            }

            form {
                padding: 0;

                button[type="submit"] {
                    display: none;
                }

                #product_filter_form {
                    display: flex;
                    flex-direction: column;
                    row-gap: 2rem;

                    @include respond-to('extra-large') {
                        column-gap: 1rem;
                        flex-direction: row;
                        flex-wrap: wrap;
                    }

                    .maier-input {
                        .filter-label {
                            display: flex;
                            font-size: $maier-font-small;
                            font-weight: $maier-font-weight-medium;
                            justify-content: space-between;
                            margin-bottom: 0;

                            @include respond-to('extra-large') {
                                font-size: $maier-font-xx-small;
                            }

                            &:after {
                                content: '\30C';
                                transform: rotateX(180deg);
                                transition: all 0.2s ease-in-out;
                                position: relative;
                                top: -0.3rem;
                            }
                        }

                        .filter-label-checkbox {
                            display: none;

                            &:checked {
                                & ~ .filter-value-list {
                                    display: flex;
                                    flex-direction: column;
                                    max-height: 80rem;
                                    opacity: 1;
                                    position: static;
                                    row-gap: 2rem;
                                    transition: max-height 0.5s, opacity 0.3s;

                                    li {
                                        display: block;
                                        flex: none;
                                    }
                                }

                                & ~ .filter-label {
                                    &:after {
                                        transform: rotateX(0deg);
                                        transition: all 0.2s ease-in-out;
                                        top: 0.75rem;
                                    }
                                }
                            }
                        }

                        .filter-value-list {
                            margin: 0;
                            max-height: 0;
                            opacity: 0;
                            padding: 0;
                            position: absolute;
                            overflow: auto;

                            li {
                                display: none;

                                input {
                                    background-color: $maier-color-white;
                                    border: 1px solid $maier-color-light-grey;
                                    margin-right: 1rem;
                                }

                                label {
                                    font-size: $maier-font-smaller;

                                    @include respond-to('extra-large') {
                                        font-size: $maier-font-xxx-small;
                                    }
                                }

                                .filter-value-list-item-count {
                                    display: none;
                                }
                            }
                        }
                    }
                }


                .sort-section {
                    @include respond-to('extra-large') {
                        flex-basis: calc(50% - 0.5rem);
                        order: 1;
                    }

                    .sort {
                        border: 1px solid $maier-color-light-black;
                        margin: 0;
                        padding: 0.5rem;
                        padding-left: 2rem;
                    }
                }

                .enabled-filters {
                    @include respond-to('extra-large') {
                        order: 3;
                    }
                    ul {
                        display: flex;
                        flex-direction: column;
                        padding-left: 0;
                        row-gap: 0.5rem;
                    }

                    .reset-filter-item {
                        border: 1px solid $maier-color-grey;
                        border-radius: 20px;
                        cursor: pointer;
                        font-size: $maier-font-xxx-small;
                        padding: 0.5rem 3rem 0.5rem 1rem;
                        position: relative;
                        width: fit-content;

                        &:hover {
                            border-color: $maier-color-black;
                            transition: all 0.5s ease-in-out;
                        }

                        &:after {
                            content: 'x';
                            color: $maier-color-gold;
                            font-size: $maier-font-xx-small;
                            line-height: 1rem;
                            position: absolute;
                            right: 1.2rem;
                            top: 0.8rem;
                        }
                    }
                }

                .filters-section {
                    @include respond-to('extra-large') {
                        flex-basis: calc(50% - 0.5rem);
                        order: 2;
                        position: relative;
                    }

                    #filters-checkbox {
                        display: none;

                        &:checked {
                            & + label {
                                @include respond-to('extra-large') {
                                    border-radius: 3px;
                                    box-shadow: 0 0 1px 1px;
                                    display: block;
                                }

                                span {
                                    @include respond-to('extra-large') {
                                        top: 1rem;
                                        transform: rotate(135deg);
                                    }
                                }
                            }
                            & ~ .filters {
                                @include respond-to('extra-large') {
                                    display: flex;
                                    z-index: 200;
                                }
                            }
                        }
                    }

                    & > label {
                        border: 1px solid black;
                        display: none;
                        padding: 0.5rem;
                        padding-left: 2rem;
                        position: relative;
                        width: 100%;


                        @include respond-to('extra-large') {
                            display: block;
                        }

                        span {
                            position: absolute;
                            right: 1rem;
                            top: 0.3rem;
                            transform: rotate(-45deg);
                        }
                    }

                    .filters {
                        display: flex;
                        flex-direction: column;
                        row-gap: 2rem;


                        @include respond-to('extra-large') {
                            background-color: $maier-background-color-primary;
                            border: 1px solid $maier-color-black;
                            box-shadow: 0px 4px 43px -30px rgb(0, 0, 0);
                            display: none;
                            padding: 1rem;
                            position: absolute;
                            width: 100%;
                        }

                        .maier-input {
                            display: flex;
                            flex-direction: column;
                            row-gap: 2rem;

                            > div {
                                display: flex;
                                flex-direction: column;
                                row-gap: 2rem;
                            }
                        }
                    }
                }
            }
        }

        .product-list {
            flex-basis: 75%;
            max-width: 100%;

            > section {
                column-gap: 4rem;
                display: flex;
                flex-wrap: wrap;
                row-gap: 4rem;

                @include respond-to('extra-large') {
                    justify-content: space-between;
                }

                @include respond-to('small') {
                    justify-content: center;
                }

                .maier-product {
                    flex-basis: 30%;

                    @include respond-to('extra-large') {
                        flex-basis: 45%;
                    }

                    @include respond-to('small') {
                        flex-basis: 90%;
                    }

                    .maier-product-media {
                        width: 100%;
                    }
                }

                .maier-section-horizontal-list {
                    flex-basis: 60%;

                    @include respond-to('extra-large') {
                        flex-basis: 100%;
                    }

                    .maier-section-horizontal-media {
                        height: 100%;
                    }
                }
            }
        }
    }

    .maier-container-filter-form-description {
        font-size: 3rem;
        margin-bottom: 5rem;
        text-align: center;
        text-transform: uppercase;
    }
}