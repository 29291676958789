$slick-slide-column-gap: 2rem;
$slick-arrow-width: 3rem;
$slick-arrow-height: $slick-arrow-width;

.slick-slider {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .slick-next:before, .slick-prev:before {
        font-family: $maier-primary-font !important;
    }

    &.slick-vertical {
        flex-direction: column;

        .slide-arrow {
            &.prev {
                bottom: inherit;
                top: -$slick-arrow-height;
            }

            &.next {
                bottom: -$slick-arrow-height;
                top: inherit;
            }
        }

        .slick-list {
            flex-grow: inherit;
            width: 100%;

            .slick-track {
                display: block!important;
                margin: 0;

                .slick-slide {
                    padding-left: 0;
                }
            }
        }

    }

    .slick-list {
        flex-grow: 1;
        width: calc(100% - 2 * #{$slick-arrow-width});

        @include respond-to('extra-large') {
            width: 100%;
        }

        .slick-track {
            display: flex;
            justify-content: center;
            min-width: 100%;
            margin: 0 (-$slick-slide-column-gap);

            @include respond-to('extra-large') {
                margin: 0;
                column-gap: 0;
            }

            .slick-slide {
                padding-left: $slick-slide-column-gap;

                @include respond-to('extra-large') {
                    padding: 0;
                }
            }
        }
    }

    .slide-arrow {
        background-repeat: no-repeat;
        cursor: pointer;
        flex-grow: 1;
        height: $slick-arrow-height;
        margin: 0;
        position: absolute;
        text-indent: -9999px;
        width: $slick-arrow-width;
        z-index: 100;

        @include respond-to('extra-large') {
            right: 0;
        }

        &.prev {
            background-image: url("/bundles/maierfrontcommon/images/common/icons/arrow-left-grey.svg");
            background-position: center;

            left: -1 * $slick-arrow-width;
            top: 50%;
            transform: translateY(-50%);

            @include respond-to('extra-large') {
                left: -1rem;
            }
        }

        &.next {
            background-image: url("/bundles/maierfrontcommon/images/common/icons/arrow-right-grey.svg");
            background-position: center;

            right: -0.5 * $slick-arrow-width;
            top: 50%;
            transform: translateY(-50%);

            @include respond-to('extra-large') {
                right: -0.5rem;
            }
        }

        &.slide-arrow-white {
            &.prev {
                left: 2rem;
                background-image: url("/bundles/maierfrontcommon/images/common/icons/arrow-left-white.svg");
            }

            &.next {
                right: 2rem;
                background-image: url("/bundles/maierfrontcommon/images/common/icons/arrow-right-white.svg");
            }
        }
    }

    .slick-dots {
        column-gap: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;

        li {
            opacity: 0.5;


            &.slick-active {
                opacity: 1;
            }

            button {
                &:before {
                    background-color: $maier-color-black;
                    border-radius: 50%;
                }
            }
        }
    }
}