// TODO: check sass
.maier-modal {
    @import 'modal/all';

    background-color: rgba(0,0,0,0.7);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: opacity 1s;
    visibility: hidden;
    width: 100%;
    z-index: $zindex-modal;

    &.modal-open {
        opacity: 1;
        overflow: hidden;
        visibility: visible;
    }

    .modal-content {
        background-color: $maier-color-white;
        border: 1px solid $maier-color-gold;
        left: 50%;
        max-height: 100%;
        max-width: 100%;
        overflow: auto;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);

        &::-webkit-scrollbar {
            display: none;
        }

        .close {
            background-color: transparent;
            cursor: pointer;
            font-size: 3rem;
            padding: 0 $spacer-tiny-plus;
            position: absolute;
            right: 0;
            top: 0;
            transition: color .5s;
            z-index: 999;

            &.modal-external-close {
                background-color: $maier-color-black;
                color: $maier-color-white;
                font-size: 2.5rem;
                font-weight: 200;
                height: 3rem;
                right: -3rem;
                top: -3rem;
                width: 3rem;

                @include respond-to("medium") {
                    right: 0;
                }
            }

            &:hover {
                color: $maier-color-gold;
            }
        }
    }
}
