.maier-modal {
    @import 'modal/all';

    .modal-content {

        article {
            padding: $spacer-medium;

            section {
                text-align: left;

                p {
                    margin: 0;
                }
            }
        }

        h2 {
            margin-bottom: $spacer-small-plus;
            padding: 0;
        }
    }
}
