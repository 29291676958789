@import 'components/modal';
@import 'pages/all';

.maier-customer-container {
    @import 'components/all';
    @import 'widgets/all';

    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 2rem $maier-layout-default-padding;
    row-gap: 2rem;

    @include respond-to('extra-large') {
        padding: 2rem;
    }

    > * {
        width: 100%;
    }

    & > section.maier-banner,
    & > section.maier-carousel,
    & > section.maier-media,
    & > section.maier-product,
    & > section.maier-section,
    & > section.maier-video {
        padding-left: 0;
        padding-right: 0;
    }
}

