.maier-customer-workflow {
    display: flex;
    flex-direction: column;
    row-gap: 4rem;
    width: 80%;

    @include respond-to('extra-large') {
        width: 100%
    }

    .maier-customer-workflow-title {
        text-align: center;
    }

    .maier-button {
        @include respond-to('extra-large') {
            width: 100%;
        }
    }

    #idci_step_navigator__content {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
    }

    form {
        select, input[type="text"], input[type="email"], input[type="number"], textarea {
            background-color: $maier-background-color-secondary;
            margin: 0;
        }

        .maier-input.maier-input-checkbox {
            margin: 0;
        }

        .hidden {
            display: none;
        }
    }
}