&.maier-section-article {
    display: flex;
    flex-direction: column;
    padding: 4rem $maier-layout-default-padding;
    row-gap: 4rem;

    @include respond-to("extra-large") {
        padding: 4rem 0;
    }

    .maier-section-article-title {
        text-align: center;
    }

    .maier-section-article-list {
        column-count: 2;
        column-rule: 1px solid $maier-background-color-secondary;
        column-gap: 8rem;
        width: 100%;

        @include respond-to("extra-large") {
            column-count: 1;
        }

        .maier-section-article-list-item {
            break-inside: avoid;
            display: flex;
            flex-direction: column;
            padding-bottom: 8rem;
            position: relative;
            row-gap: 4rem;

            &.maier-section-article-list-item-full {
                column-span: all;
            }

            .maier-section-article-list-item-title {
                text-align: center;
            }
        }
    }
}