&.maier-form-contact {
    @include respond-to("extra-large") {
        padding: 1rem;
    }

    .maier-form-contact-source {
        @include respond-to("extra-large") {
            margin-bottom: 4rem;
            text-align: center;
        }

        .maier-form-contact-source-choice-list {
            column-gap: 1rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @include respond-to("extra-large") {
                flex-direction: column;
            }

            .maier-form-contact-source-choice-list-item {
                display: flex;
                flex-basis: 25%;
                flex-direction: column;
                row-gap: 2rem;
            }

            .maier-form-contact-source-choice-list-group {
                display: flex;
                flex-basis: 50%;
                justify-content: space-around;
                width: 100%;


                .maier-form-contact-source-choice-list-item {
                    flex-basis: 50%;
                }
            }
        }
    }

    .maier-form-contact-informations {
        select[id="maier_contact_form_civility"], select[id="brand_contact_form_civility"] {
            width: 11rem;

            @include respond-to("extra-large") {
                width: 100%;
            }
        }
    }

    .maier-form-contact-legal-mentions {
        align-items: center;
        display: flex;
        margin: 4rem auto;
        justify-content: center;
        text-align: center;

        .maier-form-contact-legal-mentions-check {
            display: flex;
            flex-direction: column;
            font-size: $maier-font-xx-small;
            row-gap: 2rem;

            > * {
                align-items: center;
                display: flex;
                flex-direction: column;

                input[type="radio"] {
                    border-radius: 0%;

                    &:checked:before {
                        clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
                    }
                }
            }

            #maier_contact_form_shareLocation {
                width: 100%;
            }
        }
    }

    .maier-form-contact-terms-description {
        text-align: center;
    }
}