.maier-media-display {
    &.maier-wrapper-widget-page-media-display {
        .maier-media-display-list {
            align-items: stretch;

            > a {
                display: contents;
            }

            .maier-media-display-list-item {
                .maier-media-display-list-item-link {
                    margin-top: auto;
                }
            }
        }
    }
}