.page-product-alert-create {
    .maier-section-horizontal-list {
        margin: 8rem 0;

        @include respond-to('extra-large') {
            margin: 4rem 0;
        }
    }

    .maier-section.maier-section-contact {
        @include respond-to('extra-large') {
            display: none;
        }
    }
}