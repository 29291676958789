/**********/
/* Fonts  */
/**********/

$maier-primary-font: 'Roboto';

$maier-font-xxxx-small: 1rem;
$maier-font-xxx-small: 1.2rem;
$maier-font-xx-small: 1.4rem;
$maier-font-x-small: 1.6rem;
$maier-font-smaller: 1.7rem;
$maier-font-small: 1.8rem;
$maier-font-medium: 1.9rem;
$maier-font-large: 2.2rem;
$maier-font-larger: 2.6rem;
$maier-font-x-large: 2.9rem;
$maier-font-xx-large: 3.2rem;
$maier-font-xxx-large: 3.5rem;

$maier-font-weight-thin: 100;
$maier-font-weight-light: 300;
$maier-font-weight-medium: 500;
$maier-font-weight-large: 700;

$font-url: "./../../../../fonts";

@include font-face("Roboto", $font-url + "/common/Roboto/Roboto-Regular-webfont", $maier-font-weight-medium, normal, woff);
@include font-face("Roboto", $font-url + "/common/Roboto/Roboto-Italic-webfont", $maier-font-weight-medium, italic, woff);
@include font-face("Roboto", $font-url + "/common/Roboto/Roboto-Light-webfont", $maier-font-weight-light, normal, woff);
@include font-face("Roboto", $font-url + "/common/Roboto/Roboto-LightItalic-webfont", $maier-font-weight-light, italic, woff);
@include font-face("Roboto", $font-url + "/common/Roboto/Roboto-Thin-webfont", $maier-font-weight-thin, normal, woff);
@include font-face("Icomoon", $font-url + "/common/Icomoon/icomoon", 500, normal, woff);