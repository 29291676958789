&.maier-media-display {
    &.maier-wrapper-shop-product-brand-media-display-categories {
        .maier-media-display-list {
            .maier-media-display-list-item {
                .maier-media-display-list-item-media {
                    max-height: 40rem;
                }
            }
        }
    }
}