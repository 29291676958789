form {
    button[type="submit"] {
        background-color: $maier-color-white;
        color: $maier-color-black;
        display: block;
        font-size: 2.2rem;
        margin: auto;
        margin-top: 2rem;
        text-decoration: underline;
        text-decoration-color: $maier-color-orange;
        text-transform: uppercase;
        text-underline-offset: 0.5rem;

        &:focus {
            outline: 0;
        }
    }

    label {
        margin-bottom: 1rem;

        &.required {
            &:after {
                content: '*';
            }
        }
    }

    input, button, select, textarea {
        box-shadow: none !important;
    }

    input[type="radio"], input[type="checkbox"] {
        appearance: none;
        border: 1px solid $maier-color-black;
        height: 2.4rem;
        margin: auto;
        margin-right: 1rem;
        position: relative;
        vertical-align: middle;
        width: 2.4rem;

        &:before {
            content: '\00d7';
            font-size: 0;
            opacity: 0;
            transition: opacity .5s ease-in-out;
        }

        &:checked:before {
            background-color: #CD5B1B;
            height: 1.8rem;
            left: 0.2rem;
            opacity: 1;
            position: absolute;
            top: 0.2rem;
            transform-origin: bottom left;
            width: 1.8rem;
        }

        + label {
            display: inline;
            margin: 0;
            vertical-align: middle;
        }
    }

    input[type="radio"] {
        border-radius: 50%;

        &:checked:before {
            clip-path: circle(50% at 50% 50%);
        }

        + label {
            margin-right: 2rem;
        }
    }

    input[type="checkbox"] {
        &:checked:before {
            clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
        }

        &[required] {
            ~ label {
                &:after {
                    content: ' *';
                }
            }
        }

        ~ label {
            .maier-link {
                font-size: initial;
            }
        }
    }

    input[type="text"], input[type="email"], input[type="number"], input[type="file"], input[type="password"], textarea, select {
        background-color: $maier-color-lighter-grey;
        border: none;
        margin-bottom: 2rem;
        opacity: 1;
        width: 100%;
    }

    select {
        padding: 0.5rem 1rem;
        padding-left: 2rem;
    }

    .maier-input {
        width: 100%;

        &.has-help {

            p {
                font-size: 1.5rem;
                font-style: italic;
                font-weight: 500;
            }
    
            label {
                margin-bottom: 0;
            }
        }

        &.maier-input-checkboxes {
            display: flex;
            flex-wrap: wrap;
            margin: 2rem 0;
            row-gap: 2rem;
            width: 80%;

            @include respond-to('extra-large') {
                flex-direction: column;
            }

            .maier-input-checkbox {
                flex: 0 0 33.33%;
                margin: 0;
                text-align: start;
            }
        }

        &.maier-input-checkbox {
            margin-top: 2rem;
        }

        &.maier-input-radio {
            display: inline-block;
            margin: 1rem 0;
            width: initial;
        }

        &.maier-input-phone-number {

            > div {
                display: flex;
                column-gap: 2rem;

                @include respond-to('extra-large') {
                    flex-direction: column;
                }

                select {
                    flex-basis: 30%;
                    min-width: 10rem;
                    padding-right: 2rem;

                    @include respond-to('extra-large') {
                        min-width: 0;
                    }
                }

                input {
                    flex-basis: 70%;

                    @include respond-to('extra-large') {
                        margin-left: 0;
                    }
                }
            }
        }

        &.maier-input-file {
            display: block;
            height: 3.5rem;
            margin-bottom: 2rem;
            position: relative;

            input[type="file"] {
                cursor: pointer;
                height: 100%;
                margin: 0;
                opacity: 0;
                position: absolute;
            }

            span {
                background-color: #F5F5F5;
                display: block;
                height: 100%;
                padding: 0.5rem 2rem;
                width: 100%;
            }
        }
    }
}