&.maier-customer-order-workflow-manage-addresses {
    .maier-customer-order-workflow-addresses {
        > a {
            margin: auto;
            margin-top: 2rem;
        }

        .maier-customer-order-workflow-addresses-shipping, .maier-customer-order-workflow-addresses-billing {
            display: flex;
            flex-direction: column;
            margin: 2rem 0;
            row-gap: 2rem;

            .maier-customer-address-list {
                @include respond-to("extra-large") {
                    row-gap: 4rem;
                }

                .maier-customer-address-list-item {
                    flex-basis: 30%;
                    flex-grow: 1;
                    max-width: calc(33% - 2rem);

                    @include respond-to("extra-large") {
                        flex-basis: 100%;
                        max-width: 100%
                    }
                }
            }
        }

        .maier-customer-order-workflow-addresses-use-same-address {
            &:checked ~ label ~ .maier-customer-order-workflow-addresses-billing {
                display: none;
            }
        }
    }
}