.page-contact-brand {
    .maier-container-form {
        border-top: 1px solid $maier-background-color-secondary;
        padding: 6rem 0;

        @include respond-to('extra-large') {
            padding: 3rem 0;
        }

        .maier-contact-information-aside {
            display: none;
        }
    }
}