.maier-video {
    display: flex;
    flex-direction: column;
    position: relative;
    row-gap: 8rem;

    @include respond-to("extra-large") {
        row-gap: 4rem;
    }

    &:last-child {
        margin-bottom: 4rem;
    }

    + .maier-video {
        padding-top: 8rem;
    }

    .maier-video-title {
        text-align: center;
    }

    .maier-video-play-button {
        background: transparent;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);

        &.hide {
            display: none;
        }

        i {
            color: white;
            font-size: 12rem;
        }
    }
}