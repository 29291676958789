.page-contact-index {
    .maier-container-form {
        border-top: 1px solid $maier-background-color-secondary;
        padding: 6rem 0rem;

        @include respond-to('extra-large') {
            padding: 3rem 0;
        }

        &.maier-container-form-with-aside {
            align-items: start;
            column-gap: 5rem;
            display: flex;
            flex-direction: row;
            padding: 6rem 7rem;

            @include respond-to('extra-large') {
                flex-direction: column;
                padding: 3rem 0;
            }

            .maier-form {
                &.maier-form-contact {
                    width: 80%;

                    @include respond-to('extra-large') {
                        width: 100%;
                    }
                }
            }

            .maier-contact-information-aside {
                align-content: center;
                background-color: $maier-color-lighter-grey;
                border: 1px solid $maier-color-grey;
                display: flex;
                flex-direction: column;
                margin-top: 0;
                padding: 3rem 2rem;
                width: 20%;

                @include respond-to('extra-large') {
                    border: none;
                    margin-top: 2rem;
                    width: 100%;
                }

                .maier-contact-information-aside-title {
                    font-size: $maier-font-medium;
                    text-align: center;
                }

                .maier-contact-information-aside-subtitle {
                    font-size: $maier-font-medium;
                    color: $maier-text-color-secondary;
                    margin-bottom: 3rem;
                    text-align: center;
                }

                .maier-contact-information-aside-description {
                    margin-bottom: 3rem;
                }

                .maier-contact-information-aside-phone-number {
                    font-weight: $maier-font-weight-large;
                    font-size: $maier-font-larger;
                    margin-bottom: 3rem;
                    text-align: center;
                }
            }
        }

        .maier-contact-information-aside {
            display: none;
        }
    }
}