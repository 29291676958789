&.maier-banner-rolex {
    background-color: $maier-background-color-primary;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-bottom: 4rem;
    row-gap: 4rem;

    .maier-banner-title {
        color: $maier-text-color-primary;
        font-size: $maier-font-larger;
    }
}