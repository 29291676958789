&.maier-section-text {
    padding: 4rem $maier-layout-default-padding;
    text-align: center;

    @include respond-to('extra-large') {
        padding: 4rem 1.5rem;
        row-gap: 4rem;
    }

    .maier-section-text-title {
        text-align: center;

        @include respond-to('extra-large') {
            font-size: $maier-font-small;
            font-weight: $maier-font-weight-large;
        }
    }

    .maier-section-text-description {
        font-size: $maier-font-x-small;
        line-height: 2rem;
        margin: auto;
        width: 75%;

        @include respond-to('extra-large') {
            width: 100%;
        }

        a {
            @extend %maier-link-tertiary;
        }
    }
}