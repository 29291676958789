&.maier-carousel-presentation {
    padding: 0;

    &:last-child {
        padding-bottom: 4rem;
    }

    .maier-carousel-presentation-list {
        padding: 0;

        .maier-carousel-presentation-list-item {
            position: relative;

            &.maier-carousel-presentation-list-item-right {
                .maier-carousel-presentation-list-item-content {
                    left: 50%;
                    right: 0;
                }
            }

            .maier-carousel-presentation-list-item-content {
                align-items: baseline;
                bottom: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                left: 0;
                right: 50%;
                padding: 7.5%;
                pointer-events: none;
                position: absolute;
                row-gap: 2rem;
                top: 0;

                @include respond-to('extra-large') {
                    align-items: center;
                    padding: 2.5%;
                    position: static;
                    text-align: center;
                }

                .maier-carousel-presentation-list-item-content-surtitle {
                    display: block;
                    font-size: $maier-font-large;

                    @include respond-to('extra-large') {
                        color: $maier-text-color-primary;
                    }
                }

                .maier-carousel-presentation-list-item-content-title {
                    display: block;
                    font-size: $maier-font-xxx-large;

                    @include respond-to('extra-large') {
                        color: $maier-text-color-primary;
                    }
                }

                .maier-carousel-presentation-list-item-content-link {
                    pointer-events: auto;
                }

                .maier-button-secondary, .maier-button-tertiary {
                    @include respond-to('extra-large') {
                        @include maier-button-primary;

                        // &:hover, &:focus-visible {
                        //     background-color: $maier-color-orange;
                        // }
                    }
                }
            }
        }

        &.slick-slider {
            .slick-list {
                width: 100%;

                .slick-track {
                    margin: 0;

                    .slick-slide {
                        padding-left: 0;
                    }
                }
            }

            .slide-arrow {
                margin: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);

                &.prev {
                    left: 2rem;
                }

                &.next {
                    right: 2rem;
                }
            }

            .maier-carousel-presentation-dots {
                bottom: -1rem;
                display: block;
                height: 6px;
                left: 50%;
                overflow: hidden;
                position: absolute;
                text-align: center;
                transform: translateX(-50%);
                width: 97px; // = 3 dots
                white-space: nowrap;

                li {
                    background-color: #D4D4D4;
                    cursor: pointer;
                    display: inline-block;
                    height: 6px;
                    margin-right: 12px;
                    text-indent: -9999em;
                    transition: all .2s linear;
                    width: 6px;
                    border-radius: 100%;

                    &.slick-active {
                        background-color: $maier-color-black;
                        width: 25px;
                        border-radius: 5px;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}