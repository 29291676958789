&.maier-wrapper-shop-product-brand-carousel {
    // padding-left: $maier-layout-default-padding;
    // padding-right: 0;

    @include respond-to('extra-large') {
        padding-left: 0;
    }

    .maier-carousel-list {
        @include respond-to('extra-large') {
            padding: 0 3rem;
        }

        @include respond-to('medium') {
            padding: 0 1rem;
        }

        .slide-arrow {
            top: calc(50% - (1rem/2));
        }

        .maier-carousel-list-item {
            @include respond-to('extra-large') {
                padding: 1rem;
            }

            .maier-carousel-list-item-media {
                height: 30rem;

                @include respond-to('medium') {
                    height: 10rem;
                }

                picture {
                    justify-content: center;

                    img {
                        object-fit: contain;
                        width: 65%;

                        @include respond-to('medium') {
                            width: 90%;
                        }
                    }
                }
            }
        }
    }
}