.maier-footer {
    font-family: $maier-primary-font;
    font-weight: $maier-font-weight-light;
    padding-bottom: 5rem;

    @include respond-to("extra-large") {
        margin-top: 4rem;
        text-align: center;
    }

    .maier-footer-services {
        align-items: center;
        background-color: $maier-background-color-secondary;
        display: flex;
        justify-content: space-between;
        padding: 2rem $maier-layout-default-padding;

        @include respond-to("extra-large") {
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 2rem;
            row-gap: 4rem;
        }

        a {
            display: flex;
            flex-basis: 10%;
            flex-direction: column;
            font-size: $maier-font-x-small;
            font-weight: $maier-font-weight-light;
            text-align: center;
            text-decoration: none;

            &:hover {
                i {
                    @include respond-to("extra-large-up") {
                        color: $maier-text-color-secondary;
                    }
                }
            }

            i {
                font-size: 4.5rem;
                margin-bottom: 1rem;
            }

            @include respond-to("extra-large") {
                flex-basis: 40%;
            }
        }
    }

    .maier-footer-informations {
        background-color: $maier-color-dark-green;
        column-gap: 5rem;
        display: flex;
        justify-content: center;
        margin-top: 5rem;
        padding: 4rem 2rem;

        @include respond-to("extra-large") {
            flex-direction: column;
            padding: 3rem 2rem;
        }

        .maier-footer-informations-reviews {
            background-color: $maier-background-color-secondary;
            flex-basis: 35%;
            padding: 2rem 4rem;

            @include respond-to("extra-large") {
                padding: 0;
            }

            .maier-footer-informations-reviews-introduction {
                @include respond-to("extra-large") {
                    display: flex;
                    justify-content: space-between;
                    padding: 2rem;
                }

                .maier-footer-informations-reviews-introduction-logo {
                    width: 45%;
                }

                .maier-footer-informations-reviews-introduction-website {
                    width: 50%;

                    .maier-footer-informations-reviews-introduction-website-review {
                        font-size: $maier-font-small;
                        font-weight: $maier-font-weight-medium;
                        margin-top: 2rem;
                        text-align: left;

                        @include respond-to("extra-large") {
                            margin: 0;
                        }

                        @include respond-to("tiny") {
                            font-size: 1rem;
                        }

                        span {
                            float: right;
                            font-weight: $maier-font-weight-medium;

                            @include respond-to("extra-large") {
                                margin: 0;
                            }
                        }
                    }

                    .maier-footer-informations-reviews-introduction-website-rate {
                        display: flex;
                        margin-top: 2rem;
                        column-gap: 1rem;

                        .maier-image-star {
                            height: 3rem;
                            width: 3rem;

                            @include respond-to("extra-large") {
                                height: 2rem;
                                width: 2rem;
                            }
                        }
                    }
                }
            }

            .maier-footer-informations-reviews-list {
                display: flex;
                font-size: $maier-font-xxx-small;
                margin-bottom: 0;
                margin-top: 2rem;
                padding-left: 0;

                @include respond-to("extra-large") {
                    padding: 2rem;
                }

                &.slick-slider {
                    .slick-list {
                        flex-basis: 90%;

                        @include respond-to("extra-large") {
                            flex-basis: 95%;
                        }

                        .slick-track {
                            display: flex;

                            .slick-slide {
                                height: inherit;
                                margin-right: 1rem;

                                @include respond-to("extra-large") {
                                    margin: 0;
                                }

                                div {
                                    height: 100%;

                                    li {
                                        height: 100%;
                                    }
                                }
                            }
                        }
                    }

                    .slide-arrow {
                        background-repeat: no-repeat;
                        cursor: pointer;
                        flex-grow: 1;
                        height: 4rem;
                        margin: 0;
                        position: absolute;
                        text-indent: -9999px;
                        width: 4rem;
                        z-index: 100;

                        @include respond-to('extra-large') {
                            right: 0;
                        }

                        &.prev {
                            background-image: url("/bundles/maierfrontcommon/images/common/icons/arrow-left-grey.svg");
                            background-position: center;

                            left: -4rem;
                            top: 50%;
                            transform: translateY(-50%);

                            @include respond-to('extra-large') {
                                left: -1rem;
                            }
                        }

                        &.next {
                            background-image: url("/bundles/maierfrontcommon/images/common/icons/arrow-right-grey.svg");
                            background-position: center;

                            right: -1rem;
                            top: 50%;
                            transform: translateY(-50%);

                            @include respond-to('extra-large') {
                                right: -0.5rem;
                            }
                        }

                        &.slide-arrow-white {
                            &.prev {
                                left: 2rem;
                                background-image: url("/bundles/maierfrontcommon/images/common/icons/arrow-left-white.svg");
                            }

                            &.next {
                                right: 2rem;
                                background-image: url("/bundles/maierfrontcommon/images/common/icons/arrow-right-white.svg");
                            }
                        }
                    }
                }


                .maier-footer-informations-reviews-list-item {
                    background-color: $maier-color-light-grey;
                    padding: 1rem;
                    text-align: left;

                    .maier-footer-informations-reviews-list-item-customer {
                        font-weight: bold;
                    }

                    .maier-footer-informations-reviews-list-item-rate {
                        display: flex;
                        margin-top: 2rem;
                        column-gap: .5rem;

                        .maier-image-star {
                            width: 2rem;
                        }
                    }

                    .maier-footer-informations-reviews-list-item-review {
                        margin-top: 2rem;
                    }

                    .maier-footer-informations-reviews-list-item-date {
                        font-weight: $maier-font-weight-medium;
                        margin-top: 2rem;
                    }
                }
            }
        }

        .maier-footer-informations-aside {
            display: flex;
            flex-basis: 50%;
            justify-content: space-around;
            margin-top: 2rem;

            @include respond-to("extra-large") {
                flex-direction: column;
                row-gap: 2rem;
            }

            .maier-footer-informations-aside-about {
                flex-basis: 30%;

                .maier-footer-informations-aside-about-title {
                    @include footer-title-primary;

                    color: $maier-text-color-secondary;
                    margin-bottom: 2rem;

                    @include respond-to("extra-large") {
                        margin: 0;
                    }
                }

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    @include respond-to("extra-large") {
                        margin: 0;
                    }

                    li {
                        margin-top: 1rem;

                        @include respond-to("extra-large") {
                            margin-top: 2rem;
                        }

                        a {
                            color: $maier-color-lighter-grey;
                            font-size: $maier-font-small;
                            font-weight: $maier-font-weight-thin;
                            text-decoration: none;
                        }
                    }
                }
            }

            .maier-footer-informations-aside-contact {
                flex-basis: 60%;

                .maier-footer-informations-aside-contact-social {
                    .maier-footer-informations-aside-contact-social-title {
                        @include footer-title-primary;

                        color: $maier-color-orange;
                    }

                    .maier-footer-informations-aside-contact-social-links {
                        column-gap: 2.5rem;
                        display: flex;
                        height: 3.5rem;
                        margin-top: 2rem;

                        @include respond-to("extra-large") {
                            column-gap: 3.5rem;
                            justify-content: center;
                        }

                        a {
                            color: $maier-text-color-tertiary;
                            font-size: 0;
                            height: 3.5rem;
                            text-decoration: none;
                            width: 3.5rem;

                            img {
                                vertical-align: top;
                            }

                            i {
                                font-size: 3.5rem;
                                vertical-align: top;
                            }
                        }
                    }
                }

                .maier-footer-informations-aside-contact-newsletter {
                    @include respond-to("extra-large") {
                        margin-top: 2rem;
                    }

                    .maier-footer-informations-aside-contact-newsletter-title {
                        @include footer-title-primary;

                        color: $maier-color-orange;
                    }

                    margin-top: 2rem;

                    .footer-newsletter {
                        margin-top: 2rem;

                        form {
                            padding-left: 0;
                            position: relative;

                            input {
                                border: none;
                                font-size: $maier-font-x-small;
                                height: 4rem;
                                padding: 1rem;
                                width: 100%;
                            }

                            button {
                                background-color: transparent;
                                box-shadow: none;
                                color: $maier-text-color-primary;
                                font-size: $maier-font-small;
                                font-weight: $maier-font-weight-light;
                                height: 4rem;
                                outline: none;
                                margin: 0;
                                padding: 0;
                                position: absolute;
                                right: 1rem;
                                text-decoration: underline;
                                text-decoration-color: $maier-color-orange;
                                text-underline-offset: 0.5rem;
                                top: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .maier-footer-allowed-payment-methods {
        display: flex;
        justify-content: space-around;
        margin: auto;
        max-width: 80%;
        padding: 4rem 10rem;

        @include respond-to("extra-large") {
            flex-wrap: wrap;
            max-width: none;
            row-gap: 4rem;
        }

        @include respond-to("medium") {
            padding: 4rem 2rem;
        }

        img {
            align-self: center;
            height: 100%;
            max-height: 4rem;

            @include respond-to("extra-large") {
                flex-basis: 25%;
                object-fit: contain;
            }
        }
    }

    .maier-footer-mandatory-links {
        display: flex;

        nav {
            width: 100%;

            ul {
                column-gap: 10rem;
                display: flex;
                justify-content: center;
                list-style: none;
                padding-left: 0;

                @include respond-to("extra-large") {
                    flex-direction: column;
                }

                li {
                    @include respond-to("extra-large") {
                        margin-top: 2rem
                    }

                    a {
                        color: $maier-text-color-primary;
                        font-size: $maier-font-medium;
                        font-weight: $maier-font-weight-light;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}