$maier-section-product-footer-width: 7rem;

.maier-section-product {
    &.maier-wrapper-shop-product-section-product {
        .maier-section-product-visual {
            .maier-section-product-visual-cover {
                .maier-section-product-visual-cover-pellet-list {
                    .maier-section-product-visual-cover-pellet-list-item {
                        &.maier-section-product-visual-cover-pellet-list-item-discount {
                            background-color: $maier-color-orange;
                            border-radius: 50%;
                            color: $maier-text-color-tertiary;
                            font-weight: $maier-font-weight-large;
                            line-height: 6rem;
                            text-align: center;
                            transform: scale(0.8);

                            sup {
                                font-size: 100%;
                                font-weight: $maier-font-weight-large;
                                top: -0.25rem;
                            }
                        }

                        &.maier-section-product-visual-cover-pellet-list-item-second-hand {
                            background-image: url(/bundles/maierfrontcommon/images/website/maier_main/widget/maier_product/occasion-label.png);
                            background-position: 50%;
                            background-repeat: no-repeat;
                        }

                        &.maier-section-product-visual-cover-pellet-list-item-exclusivity {
                            width: 8rem;
                        }
                    }
                }
            }
        }

        .maier-section-product-visual-mobile {
            .maier-section-product-visual-mobile-item {
                .maier-section-product-visual-mobile-item-pellet-list {
                    .maier-section-product-visual-mobile-item-pellet-list-item {
                        &.maier-section-product-visual-cover-pellet-list-item-discount {
                            display: none;
                        }

                        &.maier-section-product-visual-cover-pellet-list-item-second-hand {
                            background-image: url(/bundles/maierfrontcommon/images/website/maier_main/widget/maier_product/occasion-label.png);
                            background-position: 50%;
                            background-repeat: no-repeat;
                        }

                        &.maier-section-product-visual-cover-pellet-list-item-exclusivity {
                            width: 8rem;
                        }
                    }
                }
            }
        }

        .maier-section-product-informations {
            &.has-promotion {
                .maier-section-product-informations-main {
                    .maier-section-product-informations-main-action-list {
                        .maier-section-product-informations-main-action-item-add-favorite {
                            @include respond-to('extra-large') {
                                order: 1;
                            }
                        }

                        .maier-section-product-informations-main-action-item-try-in-shop {
                            @include respond-to('extra-large') {
                                order: 2;
                            }
                        }
                    }
                }
            }

            &.has-configurator {
                .maier-section-product-informations-main {
                    .maier-section-product-informations-main-action-list {
                        .maier-section-product-informations-main-action-item-add-favorite {
                            @include respond-to('extra-large') {
                                order: 1;
                            }
                        }

                        .maier-section-product-informations-main-action-item-personalize-product {
                            @extend %maier-button-personalize;

                            order: 2;

                            @include respond-to('extra-large') {
                                flex-basis: calc(100% - #{$maier-section-product-footer-width});
                                order: 2;
                            }
                        }

                        .maier-section-product-informations-main-action-item-try-in-shop {
                            align-items: center;
                            display: flex;
                            flex-basis: calc(50% - 0.5rem);
                            justify-content: center;
                            padding: 1.5rem 0;

                            @include respond-to('extra-large') {
                                flex-basis: 100%;
                                order: 3;
                            }
                        }
                    }
                }
            }

            .maier-section-product-informations-main-action-list {
                column-gap: 1rem;
                display: flex;
                flex-wrap: wrap;
                font-size: $maier-font-large;
                margin-top: 2rem;
                row-gap: 1rem;
                text-transform: uppercase;
                width: 70%;

                @include respond-to('extra-large') {
                    margin-top: 0;
                    padding: 2rem;
                    width: 100%;
                }

                .maier-section-product-informations-main-action-list-reserved, .maier-section-product-informations-main-action-list-not-orderable {
                    flex-basis: calc(100% - #{$maier-section-product-footer-width});
                    max-width: calc(100% - #{$maier-section-product-footer-width});
                    padding: 1.2rem;
                    text-align: center;
                    text-transform: uppercase;

                    @include respond-to('extra-large') {
                        flex-basis: 100%;
                        max-width: 100%;
                    }

                    & + #wish-list-add-product-form {
                        .maier-section-product-informations-main-action-item-add-favorite {
                            margin: 0;
                        }
                    }
                }

                .maier-section-product-informations-main-action-list-reserved {
                    @extend %maier-button-availability;

                    + .maier-section-product-informations-main-action-item-add-favorite, .maier-section-product-informations-main-action-item-remove-favorite {
                        padding: 1.5rem;
                    }

                    + form {
                        .maier-section-product-informations-main-action-item-add-favorite, .maier-section-product-informations-main-action-item-remove-favorite {
                            padding: 1.5rem;
                        }
                    }
                }

                .maier-section-product-informations-main-action-list-not-orderable {
                    @extend %maier-button-quaternary;

                    @include respond-to('extra-large') {
                        white-space: normal;
                    }

                    @media screen and (min-width: 1400px) {
                        font-size: $maier-font-xx-small;
                    }

                    @media screen and (min-width: 1600px) {
                        font-size: $maier-font-x-small;
                    }
                }

                a, button {
                    height: auto;
                    text-decoration: none;
                    width: 100%;
                }

                button {
                    @extend %maier-button-primary;
                }

                a {
                    @extend %maier-button-tertiary;
                }

                .maier-section-product-informations-main-action-item-add-shopping-cart {
                    flex-basis: calc(100% - #{$maier-section-product-footer-width});
                    max-width: calc(100% - #{$maier-section-product-footer-width});
                    order: 0;

                    @include respond-to('extra-large') {
                        flex-basis: 100%;
                        max-width: 100%;
                    }

                    button {
                        @extend %maier-button-primary;

                        font-size: $maier-font-x-small;
                        margin: 0;
                    }
                }

                #wish-list-add-product-form {
                    display: flex;
                }

                .maier-section-product-informations-main-action-item-add-favorite, .maier-section-product-informations-main-action-item-remove-favorite {
                    @extend %maier-button-primary;

                    align-self: flex-end;
                    line-height: 1rem;
                    margin: 0;
                    order: 1;
                    padding: 1.2rem;
                    text-align: center;
                    width: $maier-section-product-footer-width - 1rem;

                    @include respond-to('extra-large') {
                        font-size: 2.5rem;
                        padding: 1.5rem;
                    }
                }

                .maier-section-product-informations-main-action-item-try-in-shop {
                    @extend %maier-button-tertiary;

                    flex-basis: calc(100% - #{$maier-section-product-footer-width});
                    font-size: $maier-font-x-small;
                    font-weight: $maier-font-weight-light;
                    line-height: 2.5rem;
                    order: 3;
                    text-align: center;
                    white-space: nowrap;

                    @include respond-to('extra-large') {
                        font-size: $maier-font-xx-small;
                        overflow: hidden;
                        padding: 1.5rem 2rem;
                    }
                }

                .product-options {
                    .product-option-parameter {
                        position: relative;

                        select {
                            font-size: $maier-font-medium;
                            background-color: $maier-background-color-primary;
                            border: 1px solid $maier-color-black;

                            &[size="5"] {
                                background-image: none;
                            }
                        }

                        .maier-tooltip {
                            position: absolute;
                            right: 3rem;

                            .maier-tooltip-content {
                                right: -30rem;
                                top: 5rem;
                                width: 40rem;

                                @include respond-to('extra-large') {
                                    right: 0;
                                }

                                @include respond-to('medium') {
                                    width: 30rem;
                                }
                            }
                        }
                    }

                    > label {
                        column-gap: .5rem;
                        display: inline-flex;
                        font-size: 1.4rem;
                    }

                    input[type="checkbox"] {
                        ~ .product-option {
                            display: none;
                        }

                        &:checked {
                            ~ .product-option {
                                display: block;
                            }
                        }
                    }

                    .product-option {
                        margin-top: 1rem;

                        .product-option-parameter-engraving-choice {
                            display: flex;
                            column-gap: 1rem;

                            > input {
                                display: none;

                                &:checked {
                                    + label {
                                        border-color: $maier-color-black;
                                    }
                                }
                            }

                            > label {
                                align-items: center;
                                border: 1px solid $maier-color-grey;
                                display: flex;
                                font-size: 1.4rem;
                                flex-basis: 50%;
                                justify-content: center;
                                margin-right: 0;
                                padding: .5rem;
                                text-transform: initial;

                                &.product-option-parameter-engraving-english {
                                    font-family: $maier-english-font;
                                    font-size: 2rem;
                                }
                            }
                        }

                        .product-option-parameter-engraving-text {
                            margin-top: 1rem;

                            > input {
                                background-color: $maier-color-white;
                                border: 1px solid $maier-color-grey;
                            }
                        }
                    }
                }
            }
        }
    }
}