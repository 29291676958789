&.maier-customer-order-workflow-customer-account {
    form {
        button#idci_step_navigator__path_0 {
            display: none;
        }
    }

    .customer-account-list {
        column-gap: 2rem;
        display: flex;
        flex-direction: row;
        row-gap: 2rem;

        @include respond-to('extra-large') {
            flex-direction: column;
        }

        .customer-account-list-item {
            background-color: $maier-color-light-grey;
            flex: 1;
            padding: 4rem 2rem;
            text-align: center;

            &:nth-child(1) {
                display: none;
            }

            label {
                display: none;
            }

            input {
                background-color: $maier-color-white;
                margin-bottom: 2rem;
            }

            .maier-title {
                font-size: 2.2rem;
                margin-bottom: 4rem;
            }

            .maier-link {
                display: block;
                margin-top: 4rem;
            }

            .maier-button {
                margin-top: 2rem;
                padding: 1.5rem 2rem;
                width: 80%;
            }
        }
    }
}