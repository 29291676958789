$maier-banner-tertiary-border-size: 23px; // 23px as requested by MAIER

&.maier-banner-media {
    padding: 0;
    position: relative;

    &.maier-banner-media-tertiary {
        + .maier-banner-media-tertiary {
            margin-top: -($maier-banner-tertiary-border-size/2);
            z-index: 1;

            .maier-banner-media-medium {
                .maier-banner-media-medium-content {
                    &.maier-banner-media-medium-content-tertiary {
                        border-top: ($maier-banner-tertiary-border-size/2) solid black;
                    }
                }
            }
        }
    }

    .maier-banner-media-medium {
        height: 100%;
        position: relative;
        width: 100%;

        .maier-banner-media-medium-content {
            &.maier-banner-media-medium-content-tertiary {
                border: $maier-banner-tertiary-border-size solid black;
                height: 100%;
                position: absolute;
                top: 0;
                width: 100%;
            }

            .maier-banner-media-medium-content-title {
                bottom: -0.5rem;
                color: $maier-text-color-tertiary;
                font-size: 4rem;
                left: 0;
                line-height: 100%;
                margin: 0;
                position: absolute;
                text-align: left;
                text-transform: uppercase;

                &.maier-banner-media-medium-content-title-secondary {
                    color: $maier-text-color-primary;
                }

                &.maier-banner-media-medium-content-title-tertiary {
                    color: $maier-text-color-primary;
                }
            }
        }
    }

    .maier-banner-media-content {
        align-items: baseline;
        bottom: 0;
        color: $maier-text-color-tertiary;
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 0;
        right: 0;
        padding: 5%;
        position: absolute;
        top: 0;

        @include respond-to('extra-large') {
            align-items: center;
        }

        .maier-banner-media-content-title {
            font-size: 5rem;
            margin-bottom: 2rem;
        }

        .maier-banner-media-content-description {
            font-size: $maier-font-larger;
            margin-bottom: 6rem;
        }

        .maier-banner-media-content-link {
            @extend %maier-button-secondary;
        }
    }
}