[class^="maier-icon-"], [class*=" maier-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: never;
    text-transform: none;

    /* Better Font Rendering =========== */
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.maier-icon-help:before {
    content: "\e900";
}

.maier-icon-workshop:before {
    content: "\e901";
}

.maier-icon-catalog:before {
    content: "\e902";
}

.maier-icon-account:before {
    content: "\e903";
}

.maier-icon-contact:before {
    content: "\e904";
}

.maier-icon-warranty:before {
    content: "\e905";
}

.maier-icon-gifts-list:before {
    content: "\e906";
}

.maier-icon-shipping:before {
    content: "\e907";
}

.maier-icon-shop:before {
    content: "\e908";
}

.maier-icon-map:before {
    content: "\e909";
}

.maier-icon-resizing-copy:before {
    content: "\e90a";
}

.maier-icon-resizing:before {
    content: "\e90b";
}

.maier-icon-payment-10x:before {
    content: "\e90c";
}

.maier-icon-secure-payment:before {
    content: "\e90d";
}

.maier-icon-shopping-cart:before {
    content: "\e90e";
}

.maier-icon-research:before {
    content: "\e90f";
}

.maier-icon-money-back-guarantee:before {
    content: "\e910";
}

.maier-icon-customer-service:before {
    content: "\e911";
}

.maier-icon-phone:before {
    content: "\e912";
}

.maier-icon-wishlist-full:before {
    content: "\e913";
}

.maier-icon-wishlist:before {
    content: "\e914";
}

.maier-icon-facebook:before {
    content: "\e915";
}

.maier-icon-twitter:before {
    content: "\e916";
}

.maier-icon-instagram:before {
    content: "\e917";
}

.maier-icon-linkedin:before {
    content: "\e918";
}

.maier-icon-pinterest:before {
    content: "\e919";
}

.maier-icon-visa:before {
    color: #2b2964;
    content: "\e91a";
}

.maier-icon-mastercard .path1:before {
    color: rgb(0, 0, 0);
    content: "\e91b";
}

.maier-icon-mastercard .path2:before {
    color: rgb(255, 95, 0);
    content: "\e91c";
    margin-left: -1.0029296875em;
}

.maier-icon-mastercard .path3:before {
    color: rgb(235, 0, 27);
    content: "\e91d";
    margin-left: -1.0029296875em;
}

.maier-icon-mastercard .path4:before {
    color: rgb(247, 158, 27);
    content: "\e91e";
    margin-left: -1.0029296875em;
}

.maier-icon-mastercard .path5:before {
    color: rgb(247, 158, 27);
    content: "\e91f";
    margin-left: -1.0029296875em;
}

.maier-icon-paypal .path1:before {
    color: rgb(0, 154, 222);
    content: "\e920";
}

.maier-icon-paypal .path2:before {
    color: rgb(0, 47, 135);
    content: "\e921";
    margin-left: -1.0029296875em;
}

.maier-icon-paypal .path3:before {
    color: rgb(0, 47, 135);
    content: "\e922";
    margin-left: -1.0029296875em;
}

.maier-icon-paypal .path4:before {
    color: rgb(0, 154, 222);
    content: "\e923";
    margin-left: -1.0029296875em;
}

.maier-icon-paypal .path5:before {
    color: rgb(0, 47, 135);
    content: "\e924";
    margin-left: -1.0029296875em;
}

.maier-icon-paypal .path6:before {
    color: rgb(0, 154, 222);
    content: "\e925";
    margin-left: -1.0029296875em;
}

.maier-icon-paypal .path7:before {
    color: rgb(0, 154, 222);
    content: "\e926";
    margin-left: -1.0029296875em;
}

.maier-icon-paypal .path8:before {
    color: rgb(0, 33, 105);
    content: "\e927";
    margin-left: -1.0029296875em;
}

.maier-icon-paypal .path9:before {
    color: rgb(0, 47, 135);
    content: "\e928";
    margin-left: -1.0029296875em;
}

.maier-icon-sofinco .path1:before {
    color: rgb(0, 75, 68);
    content: "\e929";
}

.maier-icon-sofinco .path2:before {
    color: rgb(26, 174, 183);
    content: "\e92a";
    margin-left: -1.0029296875em;
}

.maier-icon-sofinco .path3:before {
    color: rgb(26, 174, 183);
    content: "\e92b";
    margin-left: -1.0029296875em;
}

.maier-icon-sofinco .path4:before {
    color: rgb(26, 174, 183);
    content: "\e92c";
    margin-left: -1.0029296875em;
}

.maier-icon-sofinco .path5:before {
    color: rgb(26, 174, 183);
    content: "\e92d";
    margin-left: -1.0029296875em;
}

.maier-icon-sofinco .path6:before {
    color: rgb(26, 174, 183);
    content: "\e92e";
    margin-left: -1.0029296875em;
}

.maier-icon-sofinco .path7:before {
    color: rgb(26, 174, 183);
    content: "\e92f";
    margin-left: -1.0029296875em;
}

.maier-icon-sofinco .path8:before {
    color: rgb(26, 174, 183);
    content: "\e930";
    margin-left: -1.0029296875em;
}

.maier-icon-sofinco .path9:before {
    color: rgb(235, 55, 56);
    content: "\e931";
    margin-left: -1.0029296875em;
}

.maier-icon-american-express:before {
    color: #016fd0;
    content: "\e932";
}

.maier-icon-cb3x:before {
    color: #305ca5;
    content: "\e933";
}

.maier-icon-cb4x:before {
    color: #305ca5;
    content: "\e934";
}
.maier-icon-x:before {
    color: #305ca5;
    content: "\e935";
}

.maier-icon-x1:before {
    color: #305ca5;
    content: "\e936";
}