.maier-customer-address-list {
    column-gap: 4rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: 4rem;

    @include respond-to("extra-large") {
        align-items: center;
        flex-direction: column;
    }

    .maier-customer-address-list-item {
        border: 1px solid $maier-background-color-tertiary;
        display: flex;
        flex-basis: calc(50% - 2rem);
        flex-direction: column;
        justify-content: space-between;
        padding: 2rem;
        row-gap: 2rem;

        @include respond-to("extra-large") {
            flex-basis: 100%;
            width: 100%;
        }

        .maier-customer-address-list-item-actions {
            display: flex;
            flex-direction: row;

            form {
                width: 100%;
            }

            .maier-button {
                height: 100%;
                padding: 1rem 2rem;
                width: 100%;
            }
        }

        > label {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            margin: 0;
            row-gap: 2rem;

            a {
                margin: 0 auto;
            }
        }

        input {
            display: none;

            &:checked {
                & + label, & + label:hover {
                    color: $maier-text-color-secondary;
                }
            }

            & + label {
                width: 100%;

                &:hover {
                    color: $maier-background-color-tertiary;
                }
            }
        }
    }
}