.maier-customer-page-customer-connect {
    .maier-customer-connect {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;

        .maier-connect-forms {
            column-gap: 10rem;
            display: flex;
            justify-content: space-evenly;

            @include respond-to('extra-large') {
                flex-direction: column;
                row-gap: 5rem;
            }

            .maier-connect-form {
                align-items: center;
                background-color: $maier-background-color-secondary;
                display: flex;
                flex-basis: 50%;
                flex-direction: column;
                justify-content: space-between;
                padding: 4rem;
                row-gap: 4rem;

                @include respond-to('extra-large') {
                    flex-basis: 100%;
                }

                .maier-connect-form-header {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    row-gap: 2rem;
                    text-align: center;

                    p {
                        font-size: $maier-font-small;
                    }
                }
            }
        }

    }
}