&#maier-modal-product-footer-link {
    .modal-content {
        height: 90%;
        padding: 3rem 4rem;
        width: 60%;

        @include respond-to('medium') {
            width: 80%;
        }
    }
}