&#maier-modal-add-to-cart {
    .modal-content {
        min-width: 100rem;
        top: 30%;

        @include respond-to("extra-large") {
            min-width: 0;
        }

        @include respond-to("medium") {
            top: 50%;
            width: 95%;
        }

        .content {
            .cart-notification {
                display: flex;

                @include respond-to("extra-large") {
                    flex-direction: column;
                }

                > div {
                    flex-basis: 50%;
                }

                .title {
                    font-size: $maier-font-small;
                    margin-bottom: $spacer-small-plus;
                    padding: 0;
                }

                .product-details {
                    border-right: 1px solid $maier-color-light-grey;
                    padding: 2rem 4rem;

                    .grid-2 {
                        column-gap: 2rem;

                        .product-image {
                            border: 1px solid $maier-color-light-grey;
                            padding: 1rem;
                        }
                    }

                    .product-details-secondary {
                        margin-top: 2rem;
                    }
                }

                .cart-status {
                    position: relative;

                    .cart-status-details {
                        padding: 2rem 4rem;

                        .title {
                            border-bottom: 1px solid $maier-color-light-grey;
                            padding-bottom: 1rem;
                        }
                    }

                    .actions {
                        bottom: 0;
                        padding: 1rem;
                        position: absolute;
                        width: 100%;

                        @include respond-to("extra-large") {
                            margin-bottom: 5rem;
                            position: relative;
                        }

                        .maier-button {
                            font-size: $maier-font-xx-small;
                            padding: 1.5rem 3rem;

                            @include respond-to("medium") {
                                font-size: $maier-font-xxx-small;
                                padding: 1.5rem 2rem;
                            }
                        }

                        .button-left {
                            float: left;
                        }

                        .button-right {
                            float: right;
                        }
                    }
                }
            }
        }
    }
}