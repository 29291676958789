.page-product-show {
    .maier-section {
        &.maier-section-horizontal {
            &.maier-section-horizontal-media {
                &.maier-section-horizontal-media-small {
                    .maier-section-media {
                        align-items: center;
                        display: flex;
                        justify-content: center;

                        img {
                            width: 45%;
                        }
                    }
                }
            }
        }
    }

    .apple-pay-button {
        flex-basis: calc(100% - 7rem);
        max-width: calc(100% - 7rem);
        order: 1;

        @include respond-to('extra-large') {
            flex-basis: 100%;
            max-width: 100%;
            order: 0;
        }
    }
}