&.maier-section-contact {
    align-items: start;
    margin: auto;
    padding: 4rem $maier-layout-default-padding;
    row-gap: 2rem;

    @include respond-to('extra-large') {
        align-items: center;
        padding: 1rem 0;
        width: 100%;
    }

    .maier-section-contact-description {
        margin-bottom: 0;

        @include respond-to('extra-large') {
            margin-bottom: 1rem;
            padding: 0 1.5rem;
            text-align: center;
        }
    }

    .maier-section-contact-list {
        column-gap: 8rem;
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0;
        width: 100%;

        @include respond-to('extra-large') {
            flex-direction: column;
        }

        .maier-section-contact-list-item {
            align-items: center;
            background-color: $maier-background-color-secondary;
            display: flex;
            flex-direction: column;
            padding: 4rem;
            text-align: center;
            width: 100%;

            @include respond-to('extra-large') {
                margin-bottom: 1rem;
            }

            .maier-section-contact-list-item-description {
                color: $maier-text-color-quinary;
                font-size: $maier-font-large;
                margin-bottom: 3.5rem;

                .maier-icon {
                    font-size: 4.5rem;
                    margin-right: 2rem;
                    vertical-align: middle;
                }
            }

            .maier-section-contact-list-item-link {
                text-decoration-color: $maier-text-color-secondary;
            }
        }
    }
}
