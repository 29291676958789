@keyframes fade-in {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes fade-out {
    from { opacity: 1; }
    to   { opacity: 0; }
}

@keyframes fade-in-out {
    0%,50% { opacity: 0 }
    50%,100% { opacity: 1 }
}

@mixin fade-in($delay) {
    animation: fade-in #{$delay} ease-in;
}

@mixin fade-out($delay) {
    animation: fade-out #{$delay} ease-out;
}

@mixin fade-in-out($delay) {
    animation: fade-in-out #{$delay} linear;
}
