&.maier-banner-header {
    padding: 0;

    .maier-banner-header-content {
        .maier-banner-header-content-title {
            border-left: 1px solid $maier-text-color-secondary;
            font-size: $maier-font-x-large;
            padding-left: 2rem;
            text-transform: none;
        }

        .maier-banner-header-content-description {
            margin-top: 4rem;
        }
    }

    &.maier-banner-header-vertical {
        background-color: transparent;
        padding: 0;

        .maier-banner-header-media {
            & + .maier-banner-header-content {
                @include respond-to('extra-large-up') {
                    margin-bottom: -12rem;
                    position: relative;
                    top: -12rem;
                }
            }
        }

        .maier-banner-header-content {
            background-color: $maier-background-color-primary;
            margin: auto;
            margin: 0 $maier-layout-default-padding;
            padding: 5rem 20rem;

            @include respond-to('extra-large') {
                margin: 0;
                margin-top: 4rem;
                padding: 2rem;
                padding-top: 0rem;
                text-align: center;
                top: 0;
                width: 100%;
            }

            .maier-banner-header-content-title {
                @include respond-to('extra-large') {
                    display: inline-block;
                    font-size: $maier-font-medium;
                    margin: auto;
                }
            }

            .maier-banner-header-content-title-media {
                margin-top: 2rem;
                width: 30rem;

                @include respond-to('extra-large') {
                    display: block;
                    margin: auto;
                    margin-top: 2rem;
                    width: 60%;
                }
            }

            .maier-banner-header-content-description {
                font-size: $maier-font-medium;

                @include respond-to('extra-large') {
                    font-size: $maier-font-xx-small;
                    text-align: center;
                }
            }
        }
    }

    &.maier-banner-header-horizontal {
        display: flex;

        @include respond-to('extra-large') {
            flex-direction: column;
            padding: 0;
        }

        .maier-banner-header-media {
            flex-basis: 50%;
            min-height: 0;
        }

        .maier-banner-header-content {
            background-color: $maier-background-color-secondary;
            flex-basis: 50%;
            padding-left: 4rem;
            padding-right: 4rem;

            .maier-banner-header-content-description {
                font-size: $maier-font-xx-small;
            }

            .maier-banner-header-content-title-media {
                display: none;
            }
        }

        &.maier-banner-header-horizontal-right {
            flex-direction: row-reverse;
        }
    }
}