&.maier-section-product-features {
    padding: 0 8rem;

    @include respond-to('extra-large') {
        padding: 2rem;
    }

    &:last-child {
        margin-bottom: 4rem;
    }

    .maier-section-product-features-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 5rem;
        width: 100%;

        @include respond-to('extra-large') {
            column-gap: 10%;
        }

        .maier-section-product-features-list-item {
            align-items: center;
            display: flex;
            flex-basis: 25%;
            flex-direction: column;
            text-align: center;

            @include respond-to('extra-large') {
                flex-basis: 45%;
            }

            .maier-section-product-features-list-item-label {
                color: $maier-color-orange;
                font-size: $maier-font-large;
                font-weight: $maier-font-weight-medium;
                text-transform: uppercase;

                @include respond-to('extra-large') {
                    font-size: $maier-font-xx-small;
                    font-weight: $maier-font-weight-medium;
                }
            }

            .maier-section-product-features-list-item-value {
                font-size: $maier-font-smaller;
                font-style: italic;
                padding: 0 8rem;

                @include respond-to('extra-large') {
                    font-size: $maier-font-xxx-small;
                    font-weight: $maier-font-weight-medium;
                    padding: 0 2rem;
                }
            }
        }
    }
}