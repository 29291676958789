&.maier-customer-gift-workflow-billing-address-choice {

    .shipping {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;

        .maier-customer-address-list {
            @include respond-to("extra-large") {
                row-gap: 4rem;
            }

            .maier-customer-address-list-item {
                flex-basis: 30%;
                flex-grow: 1;
                max-width: calc(33% - 2rem);

                @include respond-to("extra-large") {
                    flex-basis: 100%;
                    max-width: 100%
                }
            }
        }
    }
}