.table {
    @include respond-to('extra-large') {
        display: block;
        overflow-x: scroll;
    }

    thead {
        tr {
            background-color: $maier-color-dark-green;
            color: $maier-text-color-tertiary;
            text-transform: uppercase;

            th {
                font-weight: $maier-font-weight-medium;
                vertical-align: middle;
            }
        }
    }

    tbody {
        tr {
            td {
                text-align: center;
                vertical-align: middle;

                .maier-button {
                    padding: 1rem;
                    width: 100%;
                }
            }
        }
    }
}