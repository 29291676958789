.maier-front-page-newsletter-subscribe {
    .maier-title {
        margin: auto;
        width: 50%;

        @include respond-to("extra-large") {
            width: 80%;
        }
    }

    .maier-container-form {
        margin: 4rem auto;
        width: 50%;

        @include respond-to("extra-large") {
            width: 80%;
        }

        p {
            margin: 2rem 0;
            text-align: center;
        }
    }
}