.pagination-container {
    border-bottom: 1px solid $maier-background-color-secondary;
    border-top: 1px solid $maier-background-color-secondary;
    margin-top: 4rem;
    padding: 1.5rem 0;

    ul {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 0;

        li {
            &.active {
                a {
                    color: $maier-text-color-secondary;
                }
            }

            &:not(:last-child) {
                margin-right: 2rem;
            }

            a {
                font-weight: $maier-font-weight-medium;
            }

            .pagination-arrow {
                color: $maier-color-grey;
                font-weight: $maier-font-weight-light;

                &.pagination-arrow-next::after {
                    content: ">";
                }

                &.pagination-arrow-previous::before {
                    content: "<";
                }
            }
        }
    }
}