@mixin maier-link() {
    font-size: $maier-font-large;
    text-decoration: underline;
    text-underline-offset: 0.5rem;

    @include respond-to('extra-large') {
        font-size: $maier-font-medium;
        text-align: center;
    }

    &:hover {
        color: $maier-text-color-secondary;
        text-decoration: underline;
    }
}

%maier-link {
    @include maier-link;
}

@mixin maier-link-primary() {
    @include maier-link;

    text-transform: uppercase;
}

%maier-link-primary {
    @include maier-link-primary;
}

@mixin maier-link-secondary() {
    @include maier-link;

    text-transform: none;
    text-decoration-color: $maier-text-color-secondary;
}

%maier-link-secondary {
    @include maier-link-secondary;
}

@mixin maier-link-tertiary() {
    @include maier-link;

    color: $maier-text-color-secondary;
    font-size: $maier-font-x-small;
    line-height: 2rem;
    text-decoration: underline;

    &:hover {
        color: lighten($maier-text-color-secondary, 10%);
    }
}

%maier-link-tertiary {
    @include maier-link-tertiary;
}

.maier-link {
    @extend %maier-link;

    &.maier-link-primary {
        @extend %maier-link-primary;
    }

    &.maier-link-secondary {
        @extend %maier-link-secondary;
    }

    &.maier-link-tertiary {
        @extend %maier-link-tertiary;
    }
}
