&.maier-section-products {
    &.has-tabs {
        input[type=radio] {
            display: none;

            &:checked {
                + .maier-section-products-content {
                    display: block;
                }
            }
        }

        .maier-section-products-content {
            display: none;
        }

        .maier-tabs {
            column-gap: 4rem;
            display: flex;
            flex-direction: row;
            font-size: $maier-font-larger;
            justify-content: center;
            width: 100%;

            @include respond-to('extra-large') {
                padding: 0;
            }

            .maier-tab-item {
                &:not(.maier-tab-item-active) {
                    opacity: 0.5;
                }

                @include respond-to('extra-large') {
                    font-size: $maier-font-medium;
                }
            }
        }
    }

    &.maier-section-products-carousel {
        &.has-tabs {
            .maier-tabs {
                padding-right: $slick-arrow-width + $slick-slide-column-gap;

                @include respond-to('extra-large') {
                    padding-right: 0;
                }
            }
        }

        .maier-section-products-title {
            padding-right: $slick-arrow-width + $slick-slide-column-gap;

            @include respond-to('extra-large') {
                padding: 0;
            }
        }

        .maier-carousel-products {
            padding: 0;

            .maier-carousel-list {
                column-gap: 0;

                @include respond-to('extra-large') {
                    padding: 0;
                    position: relative;
                }

                .maier-carousel-list-item {
                    @include respond-to('extra-large') {
                        padding: 1rem;
                    }

                    .maier-product {
                        width: 100%;

                        .maier-product-media {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .maier-section-products-content {
        width: 100%;

        .maier-section-products-list {
            column-gap: 4rem;
            display: flex;
            flex-wrap: wrap;
            row-gap: 4rem;

            @include respond-to('extra-large') {
                column-gap: 1rem;
                padding: 0.5rem;
                padding-left: 1.5rem;
                row-gap: 2rem;
            }

            .maier-section-products-list-item {
                flex: 1 0 calc(33% - 4rem);

                @include respond-to('extra-large') {
                    flex: 1 0 calc(50% - 1rem);
                }
            }
        }
    }
}