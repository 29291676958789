.maier-customer-cart-products {
    column-gap: 4rem;
    display: flex;

    @include respond-to("extra-large") {
        flex-direction: column;
        row-gap: 4rem;
    }

    .maier-customer-cart-products-list {
        display: flex;
        flex-direction: column;
        flex-basis: 70%;
        row-gap: 2rem;

        &:only-child {
            flex-basis: 100%;
        }

        .maier-customer-cart-products-list-item {
            background-color: $maier-background-color-secondary;
            column-gap: 2rem;
            display: flex;
            padding: 4rem;
            width: 100%;

            @include respond-to("extra-large") {
                flex-direction: column;
                padding: 2rem;
            }

            .maier-customer-cart-products-list-item-media {
                img {
                    height: 18rem;
                    object-fit: cover;
                    width: 18rem;
                }
            }

            .maier-customer-cart-products-list-item-content {
                display: flex;
                flex-basis: calc(100% - 18rem);
                flex-wrap: wrap;
                justify-content: space-between;
                padding: 1rem 0;

                .maier-customer-cart-products-list-item-content-description {
                    display: flex;
                    flex-basis: 75%;
                    flex-direction: column;
                    row-gap: 1rem;
                    text-align: left;

                    @include respond-to("extra-large") {
                        flex-basis: 70%;
                    }

                    .maier-customer-cart-products-list-item-content-description-product {
                        color: $maier-text-color-secondary;
                        text-transform: uppercase;

                    }

                    .maier-customer-cart-products-list-item-content-description-brand {
                        color: $maier-color-dark-green;
                        font-size: $maier-font-medium;
                        text-transform: uppercase;
                    }

                    .maier-customer-cart-products-list-item-content-description-reference-collection {
                        column-gap: 2rem;
                        display: flex;

                        span {
                            color: $maier-text-color-secondary;
                        }
                    }
                }


                .maier-customer-cart-products-list-item-content-information {
                    align-items: flex-end;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    flex-basis: 25%;

                    @include respond-to("extra-large") {
                        flex-basis: 30%;
                    }

                    &.maier-customer-cart-products-list-item-content-information-mobile {
                        display: none;

                        @include respond-to("extra-large") {
                            align-items: start;
                            display: flex;
                            flex-basis: 100%;
                            margin-top: 2rem;
                        }

                        .maier-customer-cart-products-list-item-content-information-actions {
                            @include respond-to("extra-large") {
                                align-items: end;
                                column-gap: 2rem;
                                display: flex;
                                flex-direction: row;
                            }

                            .maier-customer-cart-products-list-item-content-information-actions-remove-from-cart {
                                form {
                                    button {
                                        margin: 0;
                                        padding: 0.5rem 1rem;
                                    }
                                }
                            }
                        }
                    }

                    .maier-customer-cart-products-list-item-content-information-old-unit-price {
                        color: $maier-text-color-quinary;
                        font-size: $maier-font-larger;
                        text-decoration: line-through;

                        @include respond-to("extra-large") {
                            font-size: $maier-font-medium;
                        }
                    }

                    .maier-customer-cart-products-list-item-content-information-current-unit-price {
                        color: $maier-text-color-secondary;
                        font-size: $maier-font-larger;
                        font-weight: $maier-font-weight-medium;
                        white-space: nowrap;

                        @include respond-to("extra-large") {
                            font-size: $maier-font-medium;
                        }
                    }

                    .maier-customer-cart-products-list-item-content-information-current-offer {
                        color: $maier-text-color-secondary;
                        font-weight: $maier-font-weight-medium;
                        white-space: nowrap;

                        @include respond-to("extra-large") {
                            font-size: $maier-font-medium;
                        }
                    }

                    .maier-customer-cart-products-list-item-content-information-actions {
                        align-items: flex-end;
                        display: flex;
                        flex-direction: column;

                        @include respond-to("extra-large") {
                            display: none;
                        }

                        .maier-customer-cart-products-list-item-content-information-actions-quantity {
                            width: 5rem;

                            input {
                                box-shadow: none;
                            }

                            form {
                                display: none;
                            }
                        }

                        .maier-customer-cart-products-list-item-content-information-actions-remove-from-cart {
                            form {
                                padding: 0;

                                button {
                                    background-color: $maier-background-color-secondary;
                                    font-size: $maier-font-xxx-small;
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .maier-customer-cart-products-total {
        background-color: $maier-background-color-secondary;
        display: flex;
        flex-basis: 30%;
        flex-direction: column;
        padding: 4rem;
        row-gap: 2rem;
        text-transform: uppercase;

        @include respond-to("extra-large") {
            padding: 2rem;
        }

        hr {
            margin: 0;
            background-color: $maier-color-grey;
            opacity: 0.2;
        }

        .maier-customer-cart-products-total-amounts {
            display: flex;
            border-bottom: 1px solid $maier-background-color-secondary;
            flex-direction: column;
            row-gap: 2rem;

            & > div {
                display: flex;
                justify-content: space-between;

                .maier-customer-cart-products-total-amounts-value {
                    color: $maier-text-color-secondary;
                    font-weight: $maier-font-weight-medium;
                    text-align: right;
                }
            }
        }

        .maier-customer-cart-products-total-actions {
            display: flex;
            flex-direction: column;
            row-gap: 2rem;

            & > div {
                display: flex;
                font-size: $maier-font-medium;
                justify-content: space-between;
                flex-direction: column;

                .apple-pay-button {
                    margin-bottom: 1rem;
                }

                .maier-customer-cart-products-total-actions-label {
                    color: $maier-color-dark-green;
                    font-weight: 700;
                }

                .maier-customer-cart-products-total-actions-value {
                    color: $maier-text-color-secondary;
                    font-weight: 700;
                }
            }

            .maier-customer-cart-products-total-actions-price {
                flex-direction: row;
            }

            .maier-customer-cart-products-total-actions-validate {
                margin: auto;
                width: 100%;
            }
        }

        .maier-customer-cart-products-total-credit {
            column-gap: 2rem;
            display: flex;
            justify-content: space-between;

            p {
                display: flex;
                flex-direction: column;
                font-size: 1rem;
                row-gap: 1rem;
                text-align: center;
                text-transform: none;

                i {
                    font-size: $maier-font-larger;
                }
            }
        }
    }
}

.maier-customer-cart-shipping-rules {
    border: 3px solid $maier-color-orange;
    padding: 2.5rem;
    margin-bottom: 2rem;
    text-align: left;

    .maier-customer-cart-shipping-rules-title {
        text-transform: uppercase;
    }
}