&.maier-form-try-in-shop {
    padding: 0;

    .maier-form-try-in-shop-terms {
        text-align: center;

        .maier-form-try-in-shop-terms-description {
            margin-top: 2rem;
        }

        input[type="radio"] {
            border-radius: 0%;

            &:checked:before {
                clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
            }
        }
    }
}