.maier-container {

    & > * {
        margin: auto;
        width: 70%;

        @include respond-to('extra-large') {
            width: 80%;
        }
    }

    &.maier-container-full {
        > * {
            width: 100%;
        }
    }

    &.maier-container-bigger {
        > * {
            width: 90%;

            @include respond-to('extra-large') {
                width: 100%;
            }
        }
    }

    &.maier-container-big {
        > * {
            width: 80%;

            @include respond-to('extra-large') {
                width: 90%;
            }
        }
    }

    &.maier-container-small {
        > * {
            width: 60%;

            @include respond-to('extra-large') {
                width: 70%;
            }
        }
    }

    & > section.maier-banner,
    & > section.maier-carousel,
    & > section.maier-media,
    & > section.maier-product,
    & > section.maier-section,
    & > section.maier-video {
        padding-left: 0;
        padding-right: 0;
    }
}