.bordered {
    border-top: 1px solid $maier-background-color-secondary;
    border-bottom: 1px solid $maier-background-color-secondary;
}

.bordered + .bordered {
    border-top: none;
}

.bordered:last-child {
    border-bottom: none;
}