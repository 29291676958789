&.maier-section-horizontal {
    &.maier-section-horizontal-media {
        &.maier-wrapper-shop-product-brand-section-horizontal-media {
            padding: 4rem $maier-layout-default-padding;

            @include respond-to('extra-large') {
                padding: 2rem 1rem;
            }

            .maier-section-horizontal-media-medium {
                height: 15rem;
                flex-basis: 30%;
                text-align: center;

                img {
                    object-fit: contain;

                    @include respond-to('extra-large') {
                        margin: auto;
                        max-width: 25rem;
                    }
                }

                iframe {
                    @include respond-to('extra-large') {
                        transform: scale(0.75);
                    }
                }
            }

            .maier-section-horizontal-media-content {
                padding: 0;
            }
        }

        &.maier-wrapper-shop-product-brand-section-horizontal-media-presentation-collection {
            padding: 4rem $maier-layout-default-padding;

            @include respond-to('extra-large') {
                padding: 0;
            }

            &.maier-section-horizontal-media-right {
                .maier-section-horizontal-media-content {
                    padding-left: 0;
                    padding-right: 4rem;

                    @include respond-to('extra-large') {
                        padding: 4rem 0;
                    }
                }
            }

            .maier-section-horizontal-media-content {
                padding: 0;
                padding-left: 4rem;

                @include respond-to('extra-large') {
                    padding: 4rem 0;
                }
            }
        }

        &.maier-wrapper-shop-product-brand-section-horizontal-media-presentation-customization {
            padding: 0 $maier-layout-default-padding;
            padding-bottom: 10rem;

            @include respond-to('extra-large') {
                padding: 0;
            }

            .maier-section-horizontal-media-content {
                .maier-section-horizontal-media-content-title {
                    font-weight: $maier-font-weight-medium;
                }

                .maier-section-horizontal-media-content-description {
                    font-style: italic;
                }
            }
        }
    }
}