&.maier-section-text-list {
    padding: 4rem $maier-layout-default-padding;
    row-gap: 2rem;
    text-align: center;

    @include respond-to('extra-large') {
        padding: 4rem 1.5rem;
        row-gap: 4rem;
    }

    .maier-section-text-list-title {
        @include respond-to('extra-large') {
            font-size: $maier-font-small;
            font-weight: $maier-font-weight-large;
        }
    }

    .maier-section-text {
        padding: 1rem $maier-layout-default-padding;
        row-gap: 2rem;

        @include respond-to('extra-large') {
            padding: 0;
            row-gap: 4rem;
        }

        .maier-section-text-description {
            width: 100%;
        }

        .maier-section-text-title {
            font-size: $maier-font-small;
            font-weight: $maier-font-weight-thin;
        }
    }
}