&.maier-form-create-alert {
    padding: 4rem 0;

    @include respond-to('extra-large') {
        padding: 3rem 0;
    }

    .maier-form-title {
        text-align: left;

        @include respond-to('extra-large') {
            text-align: center;
        }
    }

    .maier-form-create-alert-personal-informations {
        .maier-form-row:last-of-type {
            .maier-input:nth-child(1) {
                flex-basis: 33%;
            }

            .maier-input:nth-child(2) {
                flex-basis: calc(67% + 8rem);
            }
        }
    }

    .maier-form-create-alert-terms {
        margin-top: 2rem;
        text-align: center;

        input[type="radio"] {
            border-radius: 0%;

            &:checked:before {
                clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
            }
        }
    }

    .maier-form-create-alert-terms-description {
        margin-top: 2rem;
        text-align: center;
    }
}