.maier-media-display-block {
    &.maier-wrapper-shop-product-media-display-block {
        .maier-media-display-block-list {
            .maier-media-display {
                flex-basis: 28%;

                &:first-child {
                    flex-basis: 45%;
                }

                .maier-media-display-list {
                    @include respond-to('extra-large') {
                        flex-direction: column;
                    }

                    .maier-media-display-list-item {
                        @include respond-to('extra-large') {
                            width: 100%;
                        }
                    }
                }
            }

            .slick-list {
                .slick-track {
                    column-gap: 0;
                }
            }

            .slick-arrow {
                top: calc(50% + 2rem);

                img {
                    width: 10rem;
                }
            }
        }
    }
}