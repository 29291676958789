form {
    padding: 0;
    width: 100%;

    label {
        text-transform: uppercase;
    }

    button {
        font-size: $maier-font-medium;
        padding: 2rem 5rem;
    }

    select, input, textarea {
        background-color: $maier-background-color-primary;
    }

    .multiple-email-input-container {
        width: 100%;
        display: none;

        .multiple-email-input-list {
            cursor: text;
            background-color: $maier-color-white;
            border: 1px solid $maier-color-grey;
            overflow: hidden;
            padding: $spacer-small;

            li {
                float: left;
            }

            li.item {
                border: 1px solid $maier-color-orange;
                color: $maier-color-orange;
                margin: $spacer-tiny;
                padding: $spacer-tiny;
                padding-right: $spacer-medium;
                position: relative;

                a.item-close {
                    cursor: pointer;
                    position: absolute;
                    right: 3px;
                    top: $spacer-tiny;
                }
            }

            li.input-field input[type="text"]{
                border: 0;
                line-height: inherit;
                margin: $spacer-tiny;
                min-height: inherit;
                padding: $spacer-tiny;

                &:hover, &:focus {
                    border: 0;
                    padding: $spacer-tiny;
                }
            }
        }
    }
}