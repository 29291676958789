.maier-section-products {
    @import 'maier_section_products/all';

    &.maier-wrapper-shop-product-section-products {
        padding: 4rem $maier-layout-default-padding;
        padding-right: $maier-layout-default-padding - ($slick-arrow-width + $slick-slide-column-gap);

        @include respond-to('extra-large') {
            padding: 4rem 0;
        }
    }
}