.maier-customer-order-list {
    display: flex;
    flex-direction: column;
    padding: 0;
    row-gap: 2rem;

    .maier-customer-order-list-item {
        padding: 1.5rem;
        border: 1px solid $maier-color-grey;

        @include respond-to('extra-large') {
            padding: 0.5rem;
        }

        &:hover {
            border: 1px solid $maier-text-color-secondary;
        }

        a {
            display: flex;
            flex-direction: column;

            .maier-customer-order-list-item-header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 1rem;
            }

            .maier-customer-order-list-item-content {
                column-gap: 3rem;
                display: flex;

                & > * {
                    display: flex;
                }

                .maier-customer-order-list-item-content-media {
                    flex-basis: 25%;

                    @include respond-to('extra-large') {
                        flex-basis: 50%;
                    }
                }

                .maier-customer-order-list-item-content-description {
                    flex-basis: 75%;
                    flex-direction: column;
                    row-gap: 2rem;

                    @include respond-to('extra-large') {
                        flex-basis: 50%;
                    }
                }
            }
        }
    }
}