&.maier-section-shops {
    margin: 4rem auto;
    margin-bottom: 6rem;
    row-gap: 4rem;
    width: 90%;

    @include respond-to('extra-large') {
        width: 100%;
    }

    .maier-section-shops-title {
        @include respond-to('extra-large') {
            font-size: $maier-font-small;
            font-weight: $maier-font-weight-large;
        }
    }

    .maier-section-shops-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        row-gap: 4rem;
        width: 100%;

        @include respond-to('extra-large') {
            column-gap: 3rem;
        }

        .maier-section-shops-list-item {
            align-items: center;
            display: flex;
            flex: 1 0 16.6%;
            flex-direction: column;
            max-width: 16.6%;
            text-align: center;

            // For odd number of shops (ex: first line = 5 | second line = 6)
            // &:nth-child(1) {
            //     @include respond-to('extra-large-up') {
            //         margin-left: 8.3%;
            //     }
            // }

            // &:nth-child(5) {
            //     @include respond-to('extra-large-up') {
            //         margin-right: 8.3%;
            //     }
            // }

            @include respond-to('extra-large') {
                flex: 1 0 calc(50% - 3rem);
                max-width: calc(50% - 3rem);;
            }

            > a {
                width: 100%;

                &:hover {
                    .maier-section-shops-list-item-action {
                        color: $maier-color-orange;
                    }
                }
            }

            .maier-section-shops-list-item-media {
                height: 12rem;
                margin: 0 auto;
                width: 100%;

                &.has-alternate {
                    position: relative;

                    &:hover {
                        .maier-section-shops-list-item-media-main {
                            @include respond-to("extra-large-up") {
                                opacity: 0;
                            }
                        }

                        .maier-section-shops-list-item-media-alternate {
                            @include respond-to("extra-large-up") {
                                opacity: 1;
                            }
                        }
                    }

                    > picture {
                        > img {
                            object-position: bottom;
                        }
                    }

                    .maier-section-shops-list-item-media-main, .maier-section-shops-list-item-media-alternate {
                        position: absolute;
                        transition: opacity .3s ease-in;
                    }

                    .maier-section-shops-list-item-media-alternate {
                        opacity: 0;

                        img {
                            object-fit: contain;
                        }
                    }

                    .maier-section-shops-list-item-media-main {
                        opacity: 1;
                    }
                }
            }

            .maier-section-shops-list-item-title {
                align-items: center;
                color: $maier-text-color-quinary;
                display: flex;
                font-size: $maier-font-larger;
                font-weight: $maier-font-weight-thin;
                justify-content: center;
                min-height: 8rem;
                text-align: center;

                > *:not(br) {
                    display: contents;
                }
            }

            .maier-section-shops-list-item-description {
                margin-top: 1rem;
                font-weight: $maier-font-weight-medium;
            }

            .maier-section-shops-list-item-action {
                display: block;
                font-size: $maier-font-xx-small;
                margin-top: 1rem;
                padding: 0.5rem;
                text-decoration-color: $maier-text-color-secondary;
            }

            .maier-section-shops-list-item-phone-number {
                color: $maier-text-color-secondary;
                padding: 0.5rem;

                @include respond-to("extra-large-up") {
                    padding: 1rem;
                }
            }
        }
    }
}