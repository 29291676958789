.maier-toast {
    align-items: center;
    border-width: 2px;
    border-style: solid;
    display: flex;
    justify-content: space-between;
    left: 10%;
    line-height: 15px;
    padding: 10px;
    position: fixed;
    right: 10%;
    top: 100px;
    max-width: 80%;
    z-index: 9999;

    &.maier-toast-error {
        border-color: $maier-color-red;
        color: $maier-color-white;
        background: $maier-color-red;
    }

    // TODO: warning, success, info colors
    // &.maier-toast-warning {
    //     border-color: $maier-toast-warning-text-color;
    //     color: $maier-toast-warning-text-color;
    //     background: $maier-toast-warning-backgound-color;
    // }

    // &.maier-toast-success {
    //     border-color: $maier-toast-success-text-color;
    //     color: $maier-toast-success-text-color;
    //     background: $maier-toast-success-backgound-color;
    // }

    // &.maier-toast-info {
    //     border-color: $maier-toast-info-text-color;
    //     color: $maier-toast-info-text-color;
    //     background: $maier-toast-info-backgound-color;
    // }
}
