.maier-customer-menu {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: 100%;

    @include respond-to("extra-large") {
        padding: 0;
    }

    .maier-customer-menu-title {
        @include respond-to("extra-large") {
            padding: 0;
        }
    }

    .maier-customer-menu-description {
        @include respond-to("extra-large") {
            align-items: center;
        }
    }

    .maier-customer-menu-interface {
        column-gap: 2rem;
        display: flex;

        @include respond-to("extra-large") {
            flex-direction: column;
            padding: 0!important;
        }

        .maier-customer-menu-interface-menu {
            background-color: $maier-background-color-secondary;
            flex-basis: 25%;

            & + .maier-customer-menu-interface-content {
                flex-basis: 75%;
            }

            .maier-customer-menu-interface-menu-list {
                .maier-customer-menu-interface-menu-list-item {
                    transition: 0.3s ease-in-out background-color;

                    &.maier-customer-menu-interface-menu-list-item-active {
                        background-color: $maier-color-orange;

                        a {
                            color: $maier-text-color-tertiary;
                        }
                    }

                    &:not(.maier-customer-menu-interface-menu-list-item-active):hover {
                        background-color: $maier-color-dark-green;

                        a {
                            color: $maier-text-color-tertiary;
                        }
                    }

                    a {
                        display: inline-block;
                        padding: 1rem 2rem;
                        text-transform: uppercase;
                        transition: 0.3s ease-in-out color;
                        width: 100%;

                        span {
                            font-size: $maier-font-xxx-small;
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        .maier-customer-menu-interface-content {
            display: flex;
            flex-basis: 100%;
            flex-direction: column;
            row-gap: 2rem;

            .maier-customer-menu-interface-content-section {
                display: flex;
                background-color: $maier-background-color-secondary;
                flex-direction: column;
                padding: 2rem;
                row-gap: 2rem;
            }

            .maier-customer-content-empty {
                color: $maier-background-color-tertiary;
                font-size: $maier-font-large;
                text-align: center;
            }
        }
    }
}