*, ul, p, label, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;

    &:hover, &:focus {
        text-decoration: none;
    }
}

ul {
    list-style-type: none;
}

button:focus {
    outline: none;
}