&.maier-customer-order-workflow-shipping-options {

    .maier-customer-order-workflow-shipping-mode {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;

        .maier-customer-order-workflow-shipping-mode-list {
            input {
                display: none;

                &:checked + .maier-customer-order-workflow-shipping-mode-list-item {
                    &:hover {
                        p {
                            &.maier-customer-order-workflow-shipping-mode-list-item-selector {
                                .maier-customer-order-workflow-shipping-mode-list-item-selector-icon {
                                    i {
                                        &::after {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    p {
                        &.maier-customer-order-workflow-shipping-mode-list-item-selector {
                            .maier-customer-order-workflow-shipping-mode-list-item-selector-icon {
                                i {
                                    &::after {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }

                & + .maier-customer-order-workflow-shipping-mode-list-item {
                    border: 1px solid $maier-background-color-tertiary;
                    display: flex;
                    margin: 0;

                    &:hover {
                        p {
                            &.maier-customer-order-workflow-shipping-mode-list-item-selector {
                                .maier-customer-order-workflow-shipping-mode-list-item-selector-icon {
                                    i {
                                        &::after {
                                            border: 5px solid $maier-background-color-tertiary;
                                            display: block;
                                            opacity: 0.5;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    p {
                        align-items: center;
                        display: flex;
                        padding: 2rem;
                        margin: 0;
                        justify-content: center;

                        @include respond-to('extra-large') {
                            padding: 0.5rem;
                        }

                        &:not(:last-of-type) {
                            border-right: 1px solid $maier-background-color-tertiary;
                        }

                        &.maier-customer-order-workflow-shipping-mode-list-item-selector {
                            flex-basis: 10%;
                            justify-content: center;

                            .maier-customer-order-workflow-shipping-mode-list-item-selector-icon {
                                i {
                                    align-items: center;
                                    border: 1px solid $maier-background-color-tertiary;
                                    border-radius: 10rem;
                                    content: "";
                                    display: flex;
                                    height: 2rem;
                                    justify-content: center;
                                    position: relative;
                                    width: 2rem;

                                    &::after {
                                        border: 5px solid $maier-text-color-secondary;
                                        display: none;
                                        position: absolute;
                                        content: "";
                                        border-radius: 10rem;
                                        opacity: 1;
                                        height: 0.8rem;
                                        width: 0.8rem;
                                    }
                                }
                            }
                        }

                        &.maier-customer-order-workflow-shipping-mode-list-item-image {
                            flex-basis: 10%;
                            font-family: 'icomoon';
                            font-size: $maier-font-xxx-large;
                            justify-content: center;

                            @include respond-to('extra-large') {
                                font-size: $maier-font-large;
                            }

                            &.shipped::before {
                                content: "\e907";
                            }

                            &.collected::before {
                                content: "\e908";
                            }
                        }

                        &.maier-customer-order-workflow-shipping-mode-list-item-description {
                            align-items: start;
                            flex-basis: 60%;
                            flex-direction: column;
                        }

                        &.maier-customer-order-workflow-shipping-mode-list-item-price {
                            flex-basis: 20%;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}