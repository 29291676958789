@import "../../vendor/maier/front-common-bundle/Resources/assets/sass/website/maier_main/_settings/all";

@import 'components/all';
@import 'form/all';
@import 'bundles/all';
@import 'pages/all';
@import "../../vendor/maier/front-common-bundle/Resources/assets/sass/website/maier_main/widgets/all";
@import "../../vendor/maier/front-common-bundle/Resources/assets/sass/website/maier_main/wrappers/all";

html {
    @include responsiveness;
}

b {
    font-weight: $maier-font-weight-medium;
}