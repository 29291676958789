$maier-section-product-footer-width: 7rem;

&.maier-section-product {
    column-gap: 4rem;
    display: flex;
    flex-direction: row;
    padding: 4rem;

    @include respond-to('extra-large') {
        flex-direction: column;
        padding: 2rem;
        text-align: center;
    }

    .maier-section-product-visual {
        align-self: start;
        column-gap: 4rem;
        display: flex;
        flex-basis: 50%;

        @include respond-to('extra-large') {
            display: none;
        }

        .maier-section-product-visual-aside {
            display: flex;
            flex-basis: 20%;
            flex-direction: column;
            row-gap: 2rem;

            &.slick-slider {
                display: block;
                height: fit-content;

                @include respond-to('extra-large') {
                    display: none;
                }

                .slide-arrow {
                    &.next, &.prev {
                        left: 0;
                        margin: auto;
                        position: absolute;
                        right: 0;
                        rotate: 90deg;
                    }
                }
            }

            .maier-section-product-visual-aside-item {
                align-items: center;
                aspect-ratio: 1;
                border: 1px solid $maier-color-grey;
                display: flex;
                flex-basis: 33%;
                padding: 2rem;

                iframe {
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .maier-section-product-visual-cover {
            border: 1px solid $maier-color-grey;
            flex-basis: 80%;
            position: relative;

            picture, iframe {
                aspect-ratio: 1;
            }

            @include respond-to('extra-large') {
                flex-basis: 100%;
            }

            .maier-section-product-visual-cover-pellet-list {
                display: flex;
                flex-direction: column;
                padding: 2rem;
                position: absolute;
                right: 0;
                row-gap: 1rem;
                top: 0;

                .maier-section-product-visual-cover-pellet-list-item {
                    height: 6rem;
                    width: 6rem;
                }
            }
        }
    }

    .maier-section-product-visual-mobile {
        display: none;

        @include respond-to('extra-large') {
            display: block;
        }

        .maier-section-product-visual-mobile-item {
            aspect-ratio: 1/1;
            position: relative;

            iframe {
                aspect-ratio: 1/1;
                height: 100%;
            }

            .maier-section-product-visual-mobile-item-pellet-list {
                display: flex;
                flex-direction: column;
                padding: 2rem;
                position: absolute;
                right: 0;
                row-gap: 1rem;
                top: 0;

                .maier-section-product-visual-mobile-item-pellet-list-item {
                    height: 6rem;
                    width: 6rem;
                }
            }
        }

        &.slick-slider {
            .maier-section-product-visual-mobile-dots {
                bottom: -4rem;
                display: block;
                height: 6px;
                left: 50%;
                overflow: hidden;
                position: absolute;
                text-align: center;
                transform: translateX(-50%);
                width: 97px; // = 3 dots
                white-space: nowrap;

                li {
                    background-color: #D4D4D4;
                    cursor: pointer;
                    display: inline-block;
                    height: 6px;
                    margin-right: 12px;
                    text-indent: -9999em;
                    transition: all .2s linear;
                    width: 6px;
                    border-radius: 100%;

                    &.slick-active {
                        background-color: $maier-color-black;
                        width: 25px;
                        border-radius: 5px;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .maier-section-product-informations {
        display: flex;
        flex-basis: 50%;
        flex-direction: column;
        row-gap: 2rem;

        @include respond-to('extra-large') {
            width: 100%;
        }

        .maier-section-product-informations-header {
            align-items: center;
            column-gap: 2rem;
            display: flex;
            flex-direction: row;

            @include respond-to('extra-large') {
                flex-direction: column;
                padding: 0 2rem;
            }

            .maier-section-product-informations-header-content {
                align-self: center;
                flex-basis: 80%;

                @include respond-to('extra-large') {
                    display: flex;
                    flex-direction: column;
                    order: 1;
                    row-gap: 2rem;
                }

                .maier-section-product-informations-header-content-title {
                    font-family: $maier-primary-font;
                    font-size: $maier-font-x-large;
                    font-weight: $maier-font-weight-light;

                    @include respond-to('extra-large') {
                        font-size: $maier-font-medium;
                    }
                }

                .maier-section-product-informations-header-content-description {
                    font-size: $maier-font-large;

                    @include respond-to('extra-large') {
                        font-size: $maier-font-small;
                    }
                }
            }

            .maier-section-product-informations-header-media {
                max-width: 18rem;

                @include respond-to('extra-large') {
                    margin: 4rem auto;
                    width: 50%;
                    max-width: none;
                }
            }
        }

        .maier-section-product-informations-main {
            .maier-section-product-informations-main-reference,
            .maier-section-product-informations-main-manufacturer-reference,
            .maier-section-product-informations-main-collection {
                font-size: $maier-font-x-small;

                @include respond-to('extra-large') {
                    font-size: $maier-font-xx-small;
                }

                .maier-section-product-informations-main-reference-value,
                .maier-section-product-informations-main-manufacturer-reference-value,
                .maier-section-product-informations-main-collection-value {
                    color: $maier-text-color-secondary;
                }

                .maier-section-product-informations-main-collection-value {
                    text-decoration: underline;
                }
            }

            .maier-section-product-informations-main-description {
                margin-top: 2rem;
                font-style: italic;

                @include respond-to('extra-large') {
                    padding: 0 2rem;
                }
            }

            .maier-section-product-informations-main-payment {
                display: flex;
                flex-direction: column;
                margin-top: 3rem;
                row-gap: 3rem;

                .maier-section-product-informations-main-payment-gift-product {
                    align-items: center;
                    border: 1px solid $maier-color-grey;
                    column-gap: 1rem;
                    display: flex;
                    flex-direction: row;
                    height: 10rem;
                    padding-right: 6rem;
                    position: relative;
                    width: 50%;

                    @include respond-to('extra-large') {
                        width: 100%;
                    }

                    .maier-section-product-informations-main-payment-gift-product-media {
                        height: 100%;

                        @include respond-to('extra-large') {
                            max-width: 10rem;
                        }
                    }

                    .maier-section-product-informations-main-payment-gift-product-badge {
                        bottom: 0.5rem;
                        color: $maier-text-color-secondary;
                        font-weight: bold;
                        position: absolute;
                        right: 0.5rem;
                    }
                }

                .maier-section-product-informations-main-payment-price-information {
                    align-items: center;
                    column-gap: 2rem;
                    display: flex;
                    font-family: $maier-primary-font;
                    flex-wrap: wrap;

                    @include respond-to('extra-large') {
                        flex-direction: column;
                    }

                    .maier-section-product-informations-main-payment-price-information-current {
                        color: $maier-color-orange;
                        font-size: $maier-font-xxx-large;
                        font-weight: $maier-font-weight-medium;
                        margin: 0;
                    }

                    .maier-section-product-informations-main-payment-price-information-old {
                        color: $maier-text-color-quinary;
                        font-size: $maier-font-x-large;
                        margin: 0;
                        text-decoration: line-through;
                    }

                    .maier-section-product-informations-main-payment-price-information-new {
                        flex-basis: 100%;
                    }
                }

                .maier-section-product-informations-main-payment-credit {
                    font-size: $maier-font-medium;

                    @include respond-to('extra-large') {
                        font-size: $maier-font-small;
                    }
                }

                .maier-section-product-informations-main-payment-details {
                    align-items: center;
                    display: flex;
                    column-gap: 1rem;

                    @include respond-to('extra-large') {
                        justify-content: center;
                    }

                    .maier-section-product-informations-main-payment-details-media {
                        max-width: 10rem;
                    }

                    .maier-section-product-informations-main-payment-details-text {
                        color: $maier-color-blue;
                        margin: 0;
                    }
                }
            }

            .maier-section-product-informations-main-shipping {
                font-size: 1.4rem;
                margin-top: 2rem;
            }

            .maier-section-product-informations-main-promotion {
                background-color: $maier-background-color-secondary;
                display: flex;
                justify-content: space-between;
                margin-top: 2rem;
                padding: 2rem;
                width: 70%;

                @include respond-to('extra-large') {
                    padding: 1rem 2rem;
                    width: 100%;
                }

                .maier-section-product-informations-main-promotion-content {
                    flex-basis: 80%;

                    @include respond-to('extra-large') {
                        text-align: left;
                    }

                    .maier-section-product-informations-main-promotion-content-subtitle {
                        font-size: $maier-font-x-small;
                        font-weight: 700;
                        text-transform: uppercase;
                    }

                    .maier-section-product-informations-main-promotion-content-link {
                        font-style: italic;
                        text-decoration: underline;
                        text-decoration-color: $maier-color-grey;
                    }
                }

                .maier-section-product-informations-main-promotion-media {
                    flex-basis: 20%;
                }
            }

            .maier-section-product-informations-main-guide-link {
                display: flex;
                font-weight: $maier-font-weight-light;
                justify-content: right;
                text-decoration: underline;
                font-size: $maier-font-x-small;
                margin: auto;
                margin-left: 0;
                width: calc(70% - #{$maier-section-product-footer-width});

                @include respond-to('extra-large') {
                    justify-content: right;
                    margin-top: 2rem;
                    width: 95%;
                }

                & + * {
                    margin-top: 0;
                    padding-top: 0;
                }
            }

            .maier-section-product-informations-main-variation {
                margin-top: 2rem;
                width: 70%;

                @include respond-to('extra-large') {
                    padding: 0 2rem;
                    width: 100%;
                }

                .maier-section-product-informations-main-variation-list {
                    background-color: $maier-background-color-primary;
                    border: 1px solid;
                    box-shadow: none;
                    outline: none;
                    padding: 1rem;
                    text-align: center;
                    width: 100%;

                    option[disabled] {
                        display: none;
                    }
                }

                .maier-section-product-informations-main-variation-guide-link {
                    display: flex;
                    font-weight: $maier-font-weight-light;
                    justify-content: right;
                    text-decoration: underline;
                    font-size: $maier-font-x-small;
                    margin-bottom: 0.5rem;

                    @include respond-to('extra-large') {
                        justify-content: center;
                    }
                }
            }

            .maier-section-product-informations-main-footer {
                display: flex;
                justify-content: left;
                padding: 2rem;
                width: calc(70% - #{$maier-section-product-footer-width});

                @include respond-to('extra-large') {
                    padding: 0;
                    width: 100%;
                }

                .maier-section-product-informations-main-footer-link-list {
                    align-items: center;
                    column-gap: 6rem;
                    display: flex;
                    width: 100%;

                    @include respond-to('extra-large') {
                        justify-content: center;
                    }

                    .maier-section-product-informations-main-footer-link-list-item {
                        display: flex;
                        flex-basis: 25%;
                        flex-direction: column;
                        font-size: $maier-font-xxx-small;
                        text-align: center;

                        .maier-section-product-informations-main-footer-link-list-item-icon {
                            font-size: $maier-font-x-large;
                            margin-bottom: 1rem;
                        }

                        .maier-section-product-informations-main-footer-link-list-item-media {
                            margin: auto;
                            margin-bottom: 1rem;
                            width: 8rem;
                        }
                    }
                }
            }
        }
    }
}