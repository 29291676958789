.page-error {
    .maier-banner {
        &.maier-banner-media {
            .maier-banner-media-content {
                .maier-banner-media-content-title {
                    color: $maier-color-white;
                    font-size: 7rem;
                }

                .maier-banner-media-content-description {
                    font-size: $maier-font-x-large;
                    font-weight: $maier-font-weight-medium;
                    line-height: 100%;
                    text-transform: uppercase;
                }
            }
        }
    }
}