@mixin responsiveness {
    margin: auto;

    @include respond-to('medium') {
        max-width: 768px;
    }

    @include respond-to('medium-up') {
        max-width: 1024px;
    }

    @include respond-to('large-up') {
        max-width: 1216px;
    }

    @include respond-to('extra-large-up') {
        max-width: 2300px;
    }
}
