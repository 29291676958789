&.maier-section-links {
    margin: auto;
    padding: 4rem $maier-layout-default-padding;
    width: 80%;

    @include respond-to('extra-large') {
        padding: 2rem 0;
        width: 100%;
    }

    .maier-section-links-list {
        align-items: center;
        display: flex;
        justify-content: space-evenly;
        margin: 0;
        padding: 2rem;
        width: 100%;

        @include respond-to('extra-large') {
            flex-direction: column;
            row-gap: 4rem;
        }

        .maier-section-links-list-item {
            display: inline-flex;
        }
    }
}