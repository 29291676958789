&#maier-modal-product-variations {
    .modal-content {
        height: 90%;
        width: 90%;

        .maier-wrapper-shop-product-section-products-variations {
            @include respond-to('extra-large') {
                margin: 0;
                padding: 4rem;
            }
        }

        .slick-dots {
            padding-right: 6rem; // equivalent to $slick-arrow-width + $slick-slide-column-gap;

            @include respond-to('extra-large') {
                display: none!important;
            }

            li {
                button {
                    &:before {
                        background-color: $maier-color-orange;
                    }
                }
            }
        }
    }
}