.maier-banner {
    @import 'banner/all';

    background-color: $maier-color-lighter-grey;
    padding: 4rem;

    .maier-banner-title {
        color: $maier-text-color-tertiary;
        font-size: $maier-font-xx-large;
        text-align: center;
        text-transform: uppercase;
    }

    .maier-banner-icon {
        color: $maier-color-white;
        font-size: 6rem;
    }

    .maier-banner-description {
        color: $maier-text-color-tertiary;
        font-size: $maier-font-larger;
        font-weight: $maier-font-weight-thin;

        .strong {
            font-weight: 900;
        }
    }
}