.maier-tooltip {
    display: inline-block;
    position: relative;

    &::after {
        content: '\e900';
        font-family: 'Icomoon';
        font-size: $maier-font-xx-small;
    }

    &:hover {
        .maier-tooltip-content {
            display: block;
        }
    }

    .maier-tooltip-content {
        background-color: $maier-background-color-primary;
        border: 1px solid $maier-color-black;
        display: none;
        font-size: $maier-font-xxx-small;
        min-width: 30rem;
        padding: 1rem;
        position: absolute;
        text-transform: none;
        top: 2rem;
        z-index: 100;

        @include respond-to('extra-large') {
            right: 100%;
        }
    }
}