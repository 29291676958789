&.maier-banner-contact {
    align-items: center;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    row-gap: 6rem;
    margin: 4rem 0;
    padding: 4rem $maier-layout-default-padding;
    position: relative;
    text-align: center;

    @include respond-to('extra-large') {
        padding: 4rem;
    }

    &:not(.maier-banner-contact-has-media) {
        background-image: url("/bundles/maierfrontcommon/images/website/maier_main/widget/maier_banner/maier_banner_contact/banner.jpg");
        background-size: 100%;

        @include respond-to('extra-large') {
            background-image: url("/bundles/maierfrontcommon/images/website/maier_main/widget/maier_banner/maier_banner_contact/banner-mobile.jpg");
            background-size: cover;
        }
    }

    .maier-banner-contact-title {
        @include respond-to('extra-large') {
            font-size: $maier-font-larger;
            width: 100%;
        }
    }

    .maier-banner-contact-media {
        bottom: 0;
        filter: brightness(0.5);
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
    }

    .maier-banner-contact-link-list {
        align-items: center;
        display: flex;
        justify-content: space-around;
        width: 100%;

        @include respond-to('extra-large') {
            flex-direction: column;
            row-gap: 4rem;
        }

        .maier-banner-contact-link-list-item {
            display: flex;
            flex-direction: column;
            width: 20rem;

            @include respond-to('extra-large') {
                margin-bottom: 2rem;
            }

            .maier-banner-contact-link-list-item-description {
                font-size: $maier-font-large;
            }

            .maier-banner-contact-link-list-item-icon {
                margin-bottom: 1rem;
            }
        }
    }
}