.maier-title {
    color: $maier-text-color-primary;
    font-family: $maier-primary-font;
    font-size: $maier-font-xx-large;
    text-transform: uppercase;

    @include respond-to('extra-large') {
        padding: 0 1rem;
        text-align: center;
    }

    &.maier-title-secondary {
        color: $maier-text-color-secondary;
    }

    &.maier-title-tertiary {
        color: $maier-text-color-tertiary;
    }

    &.maier-subtitle {
        font-size: $maier-font-larger;
    }
}