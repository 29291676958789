.maier-carousel {
    @import 'carousel/all';

    &.maier-wrapper-shop-product-category-carousel {
        padding-left: $maier-layout-default-padding;

        @include respond-to('extra-large') {
            padding-left: 0;
        }

        .maier-carousel-list {
            @include respond-to('extra-large') {
                padding: 0 3rem;
            }

            @include respond-to('medium') {
                padding: 0 1rem;
            }

            .slide-arrow {
                top: calc(50% - (1rem/2));
            }

            .maier-carousel-list-item {
                @include respond-to('extra-large') {
                    padding: 1rem;
                }

                > a {
                    display: flex;
                    flex-direction: column;
                    row-gap: 2rem;
                }

                .maier-carousel-list-item-media {
                    margin: auto;
                }

                .maier-carousel-list-item-title {
                    font-size: $maier-font-small;
                    text-transform: initial;
                }
            }

            picture {
                width: inherit !important;
            }
        }
    }
}