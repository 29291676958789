.maier-media-display {
    &.maier-wrapper-shop-product-category-media-display {
        .maier-media-display-list {
            .maier-media-display-list-item {
                .maier-media-display-list-item-media {
                    height: 30rem;
                }
            }
        }
    }
}