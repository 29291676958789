&.maier-media-display {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 4rem $maier-layout-default-padding;
    row-gap: 4rem;

    @include respond-to('extra-large') {
        padding-left: 0;
        padding-right: 0;
    }

    &.maier-media-display-vertical {
        .maier-media-display-list {
            column-gap: 4rem;
            flex-wrap: wrap;
            flex-direction: row;

            .maier-media-display-list-item {
                flex: 1 1 calc(50% - 2rem);

                @include respond-to('extra-large') {
                    flex: 1 1 100%;
                }

                &:hover {
                    .maier-media-display-list-item-link {
                        color: $maier-text-color-secondary;
                        text-decoration: underline;
                    }
                }

                .maier-media-display-list-item-media {
                    height: 100%;

                    .maier-media-display-list-item-media-medium {
                        height: 50rem;
                    }

                    .maier-media-display-list-item-media-content {
                        color: black;
                        padding: 2rem;
                        position: static;

                        .maier-media-display-list-item-media-content-title {
                            @include respond-to('extra-large') {
                                text-decoration: underline;
                                text-decoration-color: $maier-text-color-secondary;
                                text-underline-offset: 0.5rem;
                            }
                        }
                    }
                }

                .maier-media-display-list-item-title {
                    display: block;
                    padding: 0.5rem 2rem;
                    text-align: left;
                    width: 100%;
                }

                .maier-media-display-list-item-description {
                    padding: 0.5rem 2rem;
                    text-align: left;
                }

                .maier-media-display-list-item-link {
                    display: block;
                    padding: 0.5rem 2rem;
                    text-align: left;
                    row-gap: 0;
                    width: 100%;

                    @include respond-to('extra-large') {
                        display: none;
                    }
                }
            }
        }
    }

    .maier-media-display-list {
        align-items: center;
        column-gap: 2rem;
        display: flex;
        justify-content: space-evenly;
        row-gap: 4rem;

        @include respond-to('extra-large') {
            flex-direction: column;
            padding: 0;
            width: 100%;
        }

        &.maier-media-display-list-mosaic-mobile {
            @include respond-to('extra-large') {
                column-gap: 0;
                flex-direction: row;
                flex-wrap: wrap;
            }

            .maier-media-display-list-item {
                @include respond-to('extra-large') {
                    flex-basis: 45%;
                }

                &:nth-child(n+5) {
                    @include respond-to('extra-large') {
                        display: none;
                    }
                }
            }
        }

        .maier-media-display-list-item {
            align-items: center;
            display: flex;
            flex-direction: column;
            row-gap: 1rem;

            @include respond-to('extra-large') {
                row-gap: 4rem;
            }

            &.big {
                flex-basis: 120%;
            }

            .maier-media-display-list-item-media {
                height: 50rem;
                position: relative;
                width: 100%;

                .maier-media-display-list-item-media-medium {
                    height: 100%;
                    position: relative;
                    width: 100%;

                    .maier-media-display-list-item-media-medium-title {
                        bottom: -0.5rem;
                        color: $maier-text-color-tertiary;
                        font-size: 4rem;
                        font-weight: $maier-font-weight-light;
                        left: 0;
                        line-height: 100%;
                        margin: 0;
                        padding: 0 2rem;
                        position: absolute;
                        text-align: left;
                        text-transform: uppercase;

                        &.maier-media-display-list-item-media-medium-title-secondary {
                            color: $maier-text-color-primary;
                        }

                        &.maier-media-display-list-item-media-medium-title-tertiary {
                            bottom: 2rem;
                            color: $maier-text-color-primary;
                        }
                    }
                }

                .maier-media-display-list-item-media-content {
                    bottom: 4rem;
                    color: $maier-text-color-tertiary;
                    left: 8%;
                    pointer-events: none;
                    position: absolute;

                    .maier-media-display-list-item-media-content-surtitle {
                        font-size: $maier-font-xx-small;
                        font-weight: $maier-font-weight-large;
                        text-transform: uppercase;
                    }

                    .maier-media-display-list-item-media-content-title {
                        font-size: $maier-font-medium;
                        margin-bottom: 0;
                    }

                    .maier-media-display-list-item-media-content-description {
                        font-weight: $maier-font-weight-medium;
                        margin-top: 6rem;
                    }
                }
            }

            .maier-media-display-list-item-title {
                text-align: center;
                text-transform: uppercase;

                @include respond-to('extra-large') {
                    width: 90%;
                }
            }

            .maier-media-display-list-item-description {
                text-align: center;

                @include respond-to('extra-large') {
                    width: 90%;
                }
            }

            .maier-media-display-list-item-link {
                font-size: $maier-font-medium;
                text-transform: uppercase;
            }
        }
    }
}