picture {
    display: flex;
    height: 100%;
    width: 100%;

    &.zoom-on-hover {
        overflow: hidden;

        img {
            transition: transform 10s cubic-bezier(.25, 1, .5, 1);

            @include respond-to('extra-large') {
                transition-property: none;
            }

            &:hover {
                transform: scale(1.5);

                @include respond-to('extra-large') {
                    transform: scale(1);
                }
            }
        }
    }

    img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}