&#maier-modal-try-in-shop {
    .modal-content {
        height: 90%;
        padding: 3rem 4rem;
        width: 60%;

        @include respond-to('medium') {
            width: 80%;
        }

        .maier-form-try-in-shop {
            border-top: 1px solid $maier-text-color-secondary;
            padding-top: 2rem;

            .maier-form-try-in-shop-product {
                align-items: center;
                column-gap: 2rem;
                display: flex;
                margin: 0;
                margin-bottom: 2rem;

                picture {
                    max-width: 15%;

                    @include respond-to('extra-large') {
                        max-width: 25%;
                    }
                }

                .maier-form-try-in-shop-product-content {
                    .maier-form-try-in-shop-product-content-name {
                        font-weight: $maier-font-weight-large;
                    }
                }
            }

            .maier-form-try-in-shop-identity, .maier-form-try-in-shop-contact {
                column-gap: 2rem;
                display: flex;
                justify-content: space-between;

                @include respond-to('extra-large') {
                    flex-direction: column;
                }
            }
        }
    }
}