.maier-customer-section {
    display: flex;
    background-color: $maier-background-color-secondary;
    flex-direction: column;
    padding: 2rem;
    row-gap: 2rem;
    width: 70%;

    @include respond-to('extra-large') {
        width: 100%;
    }
}