.maier-carousel {
    @import 'carousel/all';

    display: flex;
    flex-direction: column;
    padding: 4rem $maier-layout-default-padding;
    padding-right: $maier-layout-default-padding - ($slick-arrow-width + $slick-slide-column-gap);
    row-gap: 4rem;
    width: 100%;

    @include respond-to('extra-large') {
        padding: 4rem 0;
    }

    &.maier-carousel-rounded {
        .maier-carousel-list-item {
            .maier-carousel-list-item-media {
                border-radius: 50%;

                img {
                    border-radius: 50%;
                }
            }
        }
    }

    .maier-carousel-title {
        font-size: $maier-font-larger;
        font-weight: $maier-font-weight-medium;
        text-align: center;
        text-transform: uppercase;
        width: 100%;

        @include respond-to('extra-large') {
            font-size: $maier-font-small;
            font-weight: $maier-font-weight-large;

        }

        &.maier-carousel-title-left {
            text-align: left;

            @include respond-to('extra-large') {
                text-align: center;
            }

            + .maier-carousel-list {
                justify-content: left;

                .slick-track {
                    justify-content: left;
                }
            }
        }
    }

    .maier-carousel-list {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        padding: 0;

        @include respond-to('extra-large') {
            margin-top: 0;
        }

        .maier-carousel-list-item {
            flex-basis: 100%;

            .maier-carousel-list-item-title {
                text-align: center;
                text-transform: uppercase;

                b {
                    font-weight: $maier-font-weight-large;
                }
            }

            .maier-carousel-list-item-media {
                background-color: $maier-background-color-secondary;
                margin-bottom: 1rem;
            }
        }
    }
}